import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ChatPreview } from '../ChatPreview';

import { selectLastMessage, selectOrderList } from '../../app/redux/state';
import { readChatMessage, readOrderMessage } from '../../app/redux/actions';

import { OrderTabType } from '../../types';

interface Props {
  activeId: OrderTabType;
  activeIndex: number;
  onClick: (value: number) => void;
}

export const OrderList: FC<Props> = (props): ReactElement => {
  const dispatch = useDispatch();
  const orderList = useSelector(selectOrderList);
  const lastMessage = useSelector(selectLastMessage);

  const onChatClick = () => {
    props.onClick(-1);
    dispatch(readChatMessage());
  };
  const onOrderClick = (index: number) => {
    props.onClick(index);
    dispatch(readOrderMessage(orderList[props.activeId][index].unreadMessageIdList));
  };

  return (
    <>
      {lastMessage != null && (
        <ChatPreview
          lastMessage={lastMessage.value}
          time={lastMessage.time}
          name={lastMessage.author}
          avatarUrl={lastMessage.avatarURL}
          onClick={onChatClick}
          unreadMessages={0}
          active={props.activeIndex === -1}
        />
      )}
      {orderList[props.activeId].map(({ lastMessage }, index) => (
        <ChatPreview
          key={`ChatPreview-${index}`}
          lastMessage={lastMessage.value}
          time={lastMessage.time}
          name={lastMessage.author}
          avatarUrl={lastMessage.avatarURL}
          onClick={() => onOrderClick(index)}
          unreadMessages={0}
          active={props.activeIndex === index}
        />
      ))}
    </>
  );
};
