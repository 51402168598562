import React, { createContext, useState, PropsWithChildren, useEffect, useContext } from 'react';
import { has } from 'lodash';

import { getLocalization } from './service/api';

import { ILocalizationContext, ILocalizationLanguage, ITranslate } from './types';

const cLocalStorageKey = 'LocalizationContextProvider';
const cDefaultLanguageList = [
  { label: 'Русский', value: 'ru', img: '/images/ru.png', localization: 'mobile_russian' },
  { label: 'English', value: 'en', img: '/images/svg/en.svg', localization: 'mobile_english' },
  { label: 'Español', value: 'es', img: '/images/svg/flag-spain.svg', localization: 'mobile_spanish' },
  { label: 'Català', value: 'ca', img: '/images/ca.png', localization: 'mobile_catalan' },
  { label: 'Français', value: 'fr', img: '/images/fr.png', localization: 'mobile_france' }
];

export const cDefaultLanguage = cDefaultLanguageList[1];

export const LocalizationContext = createContext<ILocalizationContext>({
  isReady: false,
  language: cDefaultLanguage,
  dictionary: {},
  languageList: [],
  changeLanguage: (_language: string) => {},
  getWord: (_key: string): string => ''
});

export const LocalizationContextProvider = ({ children }: PropsWithChildren) => {
  const [language, updateLanguage] = useState<ILocalizationLanguage>(cDefaultLanguage);
  const [dictionary, setDictionary] = useState<Record<string, Record<string, string>>>({});

  const getWord = (key: string) => {
    let result = key;
    if (has(dictionary, key)) {
      result = dictionary[key][language.value];
    }
    return result;
  };

  const changeLanguage = (key: string) => {
    const language = cDefaultLanguageList.find(obj => obj.value === key);
    if (language != null) {
      updateLanguage(language);
      localStorage.setItem(cLocalStorageKey, language.value);
    }
  };

  const isReady = Object.keys(dictionary).length > 0;

  const loadDictionary = () => {
    let key = localStorage.getItem(cLocalStorageKey);

    changeLanguage(key != null ? key : cDefaultLanguage.value);

    getLocalization()
      .then(data => setDictionary(data))
      .catch(err => console.log(err));
  };

  useEffect(() => {
    loadDictionary();
  }, []);

  return (
    <LocalizationContext.Provider
      value={{
        language,
        dictionary,
        languageList: cDefaultLanguageList,
        changeLanguage,
        getWord,
        isReady
      }}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useTranslate = (): ITranslate => {
  const { language, dictionary } = useContext(LocalizationContext);
  return (key: string) => {
    let result = key;
    if (has(dictionary, key)) {
      result = dictionary[key][language.value];
    }
    return result;
  };
};
