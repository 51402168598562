import React, { FC, ReactElement } from 'react';
import { Box, Typography } from '@mui/material';

import { COLOR_BLUE, COLOR_TEXT_MAIN, COLOR_ORANGE, COLOR_WHITE } from '../../../../colors';

import { OrderTabType } from '../../../../types';

interface Props {
  id: OrderTabType;
  activeId: OrderTabType;
  title: string;
  badge: number;
  onClick: (value: OrderTabType) => void;
}

export const OrderTab: FC<Props> = (props): ReactElement => {
  const { id, activeId, title, badge } = props;
  const onClick = () => props.onClick(id);

  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: activeId === id ? COLOR_WHITE : 'transparent',
        pl: '12px',
        py: '8px',
        borderRadius: '6px',
        mr: '12px',
        '&:hover': {
          cursor: 'pointer'
        }
      }}>
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '26px',
          letterSpacing: '0.46px',
          color: activeId === id ? COLOR_BLUE : COLOR_TEXT_MAIN
        }}>
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '26px',
          letterSpacing: '0.46px',
          color: COLOR_ORANGE,
          ml: '12px'
        }}>
        {badge > 0 ? badge : ''}
      </Typography>
    </Box>
  );
};
