import React, { FC } from 'react';
import { Box } from '@mui/material';

import { OrderDetails } from './components/OrderDetails';
import { OrderAcceptDecline } from './components/OrderAcceptDecline';
import { OrderCalendarComplete } from './components/OrderCalendarComplete';

import { IOrder } from '../../../types';

import { COLOR_WHITE } from '../../../colors';

interface Props {
  order: IOrder;
  onAccept: () => void;
  onComplete: () => void;
}

export const ChatOrderHeader: FC<Props> = ({ order, onAccept, onComplete }: Props) => (
  <Box
    sx={{
      height: '36px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      m: '36px 24px 27px 25px',
      backgroundColor: COLOR_WHITE
    }}>
    <OrderDetails order={order} />
    {order.state === 0 && <OrderAcceptDecline onAccept={onAccept} />}
    {order.state === 1 && <OrderCalendarComplete onComplete={onComplete} />}
  </Box>
);
