import React, { FC, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { ButtonMain } from '../../../features/ButtonMain';
import { PhotoCardProfile } from '../../../features/PhotoCardProfile';

import { COLOR_TEXT_MAIN } from '../../../colors';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile, selectToken } from '../../../app/redux/state';
import { postImage } from '../../../service/api';
import { IExtendedService } from '../../../types';
import { has } from 'lodash';
import { updateProfile } from '../../../app/redux/actions';
import { LocalizationContext } from '../../../features/Localization';

interface Props {}

interface State {
  imageList: string[];
}

export const AddService2: FC<Props> = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const profile = useSelector(selectProfile);
  const location = useLocation();
  const navigate = useNavigate();

  const [state, setState] = useState<State>({ imageList: [] });
  const onGoBack = () => navigate('/addService/1', { state: { ...location.state } });

  const onGoNext = () => {
    const extendedServiceList: IExtendedService[] = [
      {
        serviceList: [...location.state.checkedServiceId],
        description: location.state.description,
        imageUrlList: [...state.imageList]
      }
    ];
    dispatch(
      updateProfile(
        {
          ...profile,
          extendedServiceList: has(profile, 'extendedServiceList')
            ? [...profile.extendedServiceList, ...extendedServiceList]
            : [...extendedServiceList]
        },
        true
      )
    );
    navigate('/profile');
  };

  const onGoToProfile = () => navigate('/profile');

  const onImageChange = (event: any) => {
    if (token != null && event.target.files != null && event.target.files.length > 0) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        postImage(token, [e.target.result])
          .then(data => {
            if (Array.isArray(data)) {
              setState({ imageList: [...state.imageList, ...data] });
            }
          })
          .catch(console.log);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            p: '24px',
            height: '800px',
            width: '1440px'
          }}>
          <Box
            sx={{
              width: '754px',
              m: '0 auto'
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: '10px'
              }}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  letterSpacing: 0.46,
                  color: COLOR_TEXT_MAIN,
                  fontVariantNumeric: 'lining-nums'
                }}>
                3/3
              </Typography>
              <Box
                onClick={onGoToProfile}
                component="img"
                alt="add"
                src={'/images/svg/closeOverlay.svg'}
                sx={{
                  width: '16px',
                  height: '16px',
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
              />
            </Box>
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 500,
                letterSpacing: 0.46,
                color: COLOR_TEXT_MAIN,
                mb: '24px'
              }}>
              {getWord('contractor_add_photos')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                mb: '24px'
              }}>
              {state.imageList.map((url, index) => (
                <PhotoCardProfile src={url} key={`PhotoCardProfile-${index}`} />
              ))}
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                onChange={onImageChange}
                type="file"
                name="avatar"
              />
              <label htmlFor="raised-button-file">
                <Box component="img" src={'/images/svg/addPhoto.svg'} />
              </label>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              <Typography
                onClick={onGoBack}
                sx={{
                  fontSize: '14px',
                  fontWeight: 600,
                  letterSpacing: 0.46,
                  color: COLOR_TEXT_MAIN,
                  ml: '22px',
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}>
                {getWord('contractor_back')}
              </Typography>
              <ButtonMain
                title={'contractor_finish'}
                onClick={onGoNext}
                fontSize={'14px'}
                fontWeight={600}
                lineHeight={'20px'}
                textBoxWidth={'173px'}
              />
            </Box>
          </Box>
        </Container>
      )}
    </LocalizationContext.Consumer>
  );
};
