import React, { FC, ReactElement, useContext, useState } from 'react';
import { Box, InputAdornment, TextField } from '@mui/material';
import { LocalizationContext } from '../../../Localization';
import { useDispatch, useSelector } from 'react-redux';
import { selectPhrase } from '../../../../app/redux/state';
import { updatePhrase } from '../../../../app/redux/actions';

interface Props {}

export const Search: FC<Props> = (): ReactElement => {
  const { language } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const phrase = useSelector(selectPhrase);

  const onChange = (e: any) => dispatch(updatePhrase(e.target.value, language.value));

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <TextField
          onChange={e => onChange(e)}
          id="outlined-basic"
          label={getWord('contractor_search')}
          variant="outlined"
          fullWidth={true}
          value={phrase}
          sx={{
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: '#c4c4c4',
                borderWidth: '1px'
              },
              height: '56px'
            },
            height: '56px',
            mb: '24px'
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  component="img"
                  alt="search"
                  src={'/images/svg/search.svg'}
                  sx={{
                    width: '22px',
                    height: '22px'
                  }}
                />
              </InputAdornment>
            ),
            style: {
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: 0.15,
              height: '56px',
              padding: '0px 12px 0 12px'
            }
          }}
        />
      )}
    </LocalizationContext.Consumer>
  );
};
