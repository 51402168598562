import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { COLOR_DARK_BLUE, COLOR_GREY_BACKGROUND, COLOR_TEXT_MAIN, COLOR_WHITE } from '../../colors';
import { LocalizationContext } from '../Localization';

interface Props {
  title: string;
  onClick: () => void;
  disabled: boolean;
}

export class ButtonDarkBlue extends PureComponent<Props> {
  render() {
    const { title, onClick, disabled } = this.props;
    return (
      <LocalizationContext.Consumer>
        {({ getWord }) => (
          <Box
            sx={{
              p: '8px 22px',
              borderRadius: 70,
              backgroundColor: !disabled ? COLOR_DARK_BLUE : COLOR_GREY_BACKGROUND,
              '&:hover': {
                cursor: disabled ? 'default' : 'pointer'
              }
            }}
            onClick={!disabled ? onClick : () => console.log()}>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '16px',
                lineHeight: '40px',
                fontWeight: 600,
                letterSpacing: 0.46,
                color: !disabled ? COLOR_WHITE : COLOR_TEXT_MAIN,
                opacity: disabled ? 0.6 : 1
              }}>
              {getWord(title)}
            </Typography>
          </Box>
        )}
      </LocalizationContext.Consumer>
    );
  }
}
