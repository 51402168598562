import React, { FC, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';

import { ButtonDarkBlue } from '../ButtonDarkBlue';

import { COLOR_GREY_BACKGROUND, COLOR_GREY_TEXT, COLOR_TEXT_MAIN, COLOR_WHITE } from '../../colors';
import { LocalizationContext } from '../Localization';
import { CustomSignGoogleButton } from '../LoginForm/components/CustomSignGoogleButton';
import { ChooseAppLanguage } from '../ChooseAppLanguage';

interface Props {
  onBasicRegistration: (login: string, password: string) => void;
  onSuccessReceiveGoogleCredentials: (value: any) => void;
  onNavigateLogin: () => void;
}

export const RegistrationForm: FC<Props> = (props: Props) => {
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const onChangeLogin = (event: any) => {
    event.persist();
    setLogin(event.target.value);
  };

  const onChangePassword = (event: any) => {
    event.persist();
    setPassword(event.target.value);
  };

  const onRegistration = () => {
    props.onBasicRegistration(login, password);
  };

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexBasis: '31.2%' }}>
          <Box
            sx={{
              width: '360px',
              backgroundColor: COLOR_WHITE,
              borderRadius: '8px',
              p: '16px 44px 44px 44px',
              boxShadow: '0 3px 7px #1A143248'
            }}>
            <Box
              sx={{ display: 'flex', mx: '10px', mb: '41px', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box component={'img'} sx={{ width: 71, height: 47 }} alt="BocaBoca" src={'/images/svg/boca-boca.svg'} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}>
                <Typography
                  onClick={() => console.log('')}
                  sx={{
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '63px',
                    color: COLOR_TEXT_MAIN
                  }}>
                  {getWord('contractor_login_for_customers')}
                </Typography>
                <Box
                  sx={{
                    backgroundColor: COLOR_GREY_TEXT,
                    height: '48px',
                    width: '1px',
                    mx: '10px'
                  }}
                />
                <Box
                  sx={{
                    position: 'relative'
                  }}>
                  <ChooseAppLanguage />
                </Box>
              </Box>
            </Box>
            <CustomSignGoogleButton
              onSuccess={props.onSuccessReceiveGoogleCredentials}
              title={'contractor_sign_up_with_google'}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                mb: '10px',
                gap: '10px'
              }}>
              <Box
                sx={{
                  flexGrow: 1,
                  border: '1px #BCC7D0 solid',
                  height: 0,
                  mt: '18px'
                }}
              />
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '40px',
                  fontWeight: 600,
                  color: COLOR_GREY_TEXT,
                  wordWrap: 'break-word'
                }}>
                {getWord('contractor_or')}
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  border: '1px #BCC7D0 solid',
                  height: 0,
                  mt: '18px'
                  //backgroundColor: COLOR_GREY_TEXT
                }}
              />
            </Box>
            <TextField
              sx={{
                mb: '12px'
              }}
              value={login}
              onChange={onChangeLogin}
              label={getWord('contractor_login')}
              fullWidth={true}
              variant="filled"
              InputProps={{
                disableUnderline: true,
                style: {
                  backgroundColor: COLOR_GREY_BACKGROUND,
                  borderRadius: 0
                }
              }}
            />
            <TextField
              sx={{
                mb: '24px'
              }}
              value={password}
              onChange={onChangePassword}
              label={getWord('contractor_password')}
              fullWidth={true}
              variant="filled"
              type="password"
              InputProps={{
                disableUnderline: true,
                style: {
                  backgroundColor: COLOR_GREY_BACKGROUND,
                  borderRadius: 0
                }
              }}
            />
            <Box
              sx={{
                mb: '24px'
              }}>
              <ButtonDarkBlue title={getWord('contractor_signup')} onClick={onRegistration} disabled={false} />
            </Box>
            <Typography
              onClick={props.onNavigateLogin}
              sx={{
                fontStyle: 'normal',
                fontSize: '14px',
                fontWeight: 500,
                textAlign: 'center',
                color: COLOR_TEXT_MAIN,
                '&:hover': {
                  cursor: 'pointer'
                }
              }}>
              {getWord('contractor_log_in_to_existing_account')}
            </Typography>
          </Box>
        </Box>
      )}
    </LocalizationContext.Consumer>
  );
};
