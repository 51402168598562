import React, { FC, ReactElement, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ButtonMain } from '../../../features/ButtonMain';
import { CollapseCategory } from '../../../features/CollapseCategory';

import { COLOR_TEXT_MAIN } from '../../../colors';

import { selectCategoryList, selectPhraseServiceList } from '../../../app/redux/state';
import { Search } from '../../../features/CollapseCategory/components/Search';
import { has } from 'lodash';
import { LocalizationContext } from '../../../features/Localization';
import { ServiceCheckbox } from '../../../features/CollapseCategory/components/ServiceCheckbox';

interface Props {}

interface State {
  checkedServiceId: Record<number, boolean>;
}

export const AddService0: FC<Props> = (): ReactElement => {
  const navigate = useNavigate();
  const phraseServiceList = useSelector(selectPhraseServiceList);

  const [state, setState] = useState<State>({
    checkedServiceId: {}
  });

  const onChecked = (id: number) =>
    setState({
      checkedServiceId: {
        ...state.checkedServiceId,
        [id]: has(state.checkedServiceId, id) ? !state.checkedServiceId[id] : true
      }
    });

  const onGoBack = () => navigate('/profile');

  const onGoNext = () =>
    navigate('/addService/1', { state: { checkedServiceId: Object.keys(state.checkedServiceId) } });

  const categoryList = useSelector(selectCategoryList);
  const { checkedServiceId } = state;
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        p: '24px'
      }}>
      <Box
        sx={{
          width: '754px',
          m: '0 auto'
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: '10px'
          }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              letterSpacing: 0.46,
              color: COLOR_TEXT_MAIN,
              fontVariantNumeric: 'lining-nums'
            }}>
            1/3
          </Typography>
          <Box
            onClick={onGoBack}
            component="img"
            alt="add"
            src={'/images/svg/closeOverlay.svg'}
            sx={{
              width: '16px',
              height: '16px',
              '&:hover': {
                cursor: 'pointer'
              }
            }}
          />
        </Box>
        <LocalizationContext.Consumer>
          {({ getWord }) => (
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 500,
                letterSpacing: 0.46,
                color: COLOR_TEXT_MAIN,
                mb: '24px'
              }}>
              {getWord('contractor_select_service')}
            </Typography>
          )}
        </LocalizationContext.Consumer>
        <Search />
        <Box sx={{ mb: '80px' }}>
          {phraseServiceList.length === 0
            ? categoryList.map(category => (
                <CollapseCategory
                  key={category.id}
                  id={category.id}
                  name={category.value}
                  checkedServiceId={checkedServiceId}
                  onChecked={onChecked}
                />
              ))
            : phraseServiceList.map(s => (
                <ServiceCheckbox key={`service-${s.id}`} service={s} checkedId={checkedServiceId} onClick={onChecked} />
              ))}
        </Box>
        <Box sx={{ position: 'fixed', top: '89vh', width: '100%' }}>
          <Box sx={{ mt: '24px', height: '36px', width: '754px', display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonMain
              title={'mobile_next'}
              onClick={onGoNext}
              fontSize={'14px'}
              fontWeight={600}
              lineHeight={'20px'}
              textBoxWidth={'173px'}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
