import axios, { AxiosResponse } from 'axios';

import env from '../../../../environment.json';

import { ILocalizationWord } from '../../types';

export let axiosInstance = axios.create();

const cDictionaryURL = '/v1/localization';

export const getLocalization = async (): Promise<Record<string, Record<string, string>>> => {
  let result: Record<string, any> = {};
  try {
    const response: AxiosResponse<{ words: ILocalizationWord[] }> = await axiosInstance.get(
      // @ts-ignore
      `${env[env.environment].host}${cDictionaryURL}`
    );
    response.data.words.forEach(word => (result[word.key] = { ...word.value }));
  } catch (error) {
    result = {};
    console.log(error);
  }
  return result;
};
