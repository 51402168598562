import React, { FC, ReactElement } from 'react';
import { Box, Typography } from '@mui/material';

import { COLOR_ORANGE, COLOR_WHITE } from '../../colors';

import env from '../../environment.json';

interface Props {
  avatarUrl: string | null;
  fullName: string;
}

export const Avatar: FC<Props> = ({ avatarUrl, fullName }): ReactElement =>
  avatarUrl != null ? (
    <Box
      component="img"
      sx={{
        height: 36,
        width: 35,
        borderRadius: '50%',
        objectFit: 'cover'
        // maxHeight: { xs: 233, md: 167 },
        // maxWidth: { xs: 350, md: 250 },
      }}
      alt="avatar"
      //@ts-ignore
      src={`${env[env.environment].host}/${avatarUrl}`}
    />
  ) : (
    <Box
      sx={{
        height: 36,
        width: 35,
        borderRadius: '50%',
        backgroundColor: COLOR_ORANGE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Typography
        sx={{
          color: COLOR_WHITE
        }}>
        {fullName != null && fullName.length > 0 ? fullName[0] : 'A'}
      </Typography>
    </Box>
  );
