import React, { FC, ReactElement } from 'react';
import { Box, Typography } from '@mui/material';

import { Avatar } from '../../../../Avatar';

import { COLOR_GREY_BACKGROUND, COLOR_TEXT_MAIN } from '../../../../../colors';

import { IOrder } from '../../../../../types';

interface Props {
  order: IOrder;
}

export const OrderDetails: FC<Props> = ({ order }): ReactElement => (
  <Box
    sx={{
      height: '36px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }}>
    <Avatar avatarUrl={order.userAvatarURL} fullName={order.userFullName} />
    <Typography
      sx={{
        color: COLOR_TEXT_MAIN,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '16px',
        m: '0 19px 0 12px'
      }}>
      {order.userFullName}
    </Typography>
    <Box
      sx={{
        width: '1px',
        height: '100%',
        mr: '19px',
        backgroundColor: COLOR_GREY_BACKGROUND
      }}
    />
    <Typography
      sx={{
        color: COLOR_TEXT_MAIN,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
        width: '320px',
        flexGrow: 0.5
      }}>
      {order.serviceFullName}
    </Typography>
  </Box>
);
