import React, { FC, ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { has } from 'lodash';

import { COLOR_TEXT_MAIN, COLOR_WHITE } from '../../../colors';

const flagSourceByTitle: Record<string, string> = {
  English: '/images/flag-en.png',
  Русский: '/images/flag-ru.png'
};

interface Props {
  title: string;
}

export const LanguageView: FC<Props> = ({ title }): ReactElement | null => {
  if (!has(flagSourceByTitle, title)) {
    return null;
  }
  return (
    <Box
      sx={{
        width: '86px',
        height: '18px',
        p: '6px 12px',
        borderRadius: '100px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: COLOR_WHITE,
        mr: '3px'
      }}>
      <Box
        component="img"
        alt="avatar"
        src={flagSourceByTitle[title]}
        sx={{
          width: '20px',
          height: '18px',
          objectFit: 'cover',
          mr: '5px'
        }}
      />
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 400,
          letterSpacing: 0.16,
          lineHeight: '18px',
          color: COLOR_TEXT_MAIN
        }}>
        {title}
      </Typography>
    </Box>
  );
};
