import React, { FC, useContext, useEffect, useRef } from 'react';
import { Box, Container, Link, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LoginForm } from '../../features/LoginForm';
import { LocalizationContext } from '../../features/Localization';

import { COLOR_GREY_BACKGROUND, COLOR_TEXT_MAIN } from '../../colors';

import { selectProfile } from '../../app/redux/state';
import { authorization, basicLogin } from '../../app/redux/actions';

interface Props {}

export const Login: FC<Props> = (_props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { language } = useContext(LocalizationContext);
  const profile = useSelector(selectProfile);

  const userId = useRef<number | null>(null);

  useEffect(() => {
    if (userId.current == null && profile.id != null) {
      userId.current = profile.id;
      navigate('/', {
        replace: true
      });
    }
  }, [profile.id]);

  const onBasicLogin = (login: string, password: string) => {
    dispatch(basicLogin(login, password, language.value));
  };
  const onSuccessReceiveGoogleCredentials = ({ code }: any) => {
    //sendAnalyticsEvent('eRegisterSuccess');
    dispatch(authorization(null, code, language.value));
  };

  const onNavigateRegistration = () => navigate('/registration');

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100vh',
            backgroundColor: COLOR_GREY_BACKGROUND
          }}>
          <Box sx={{ flexBasis: '34.4%' }} />
          <LoginForm
            onBasicLogin={onBasicLogin}
            onSuccessReceiveGoogleCredentials={onSuccessReceiveGoogleCredentials}
            onNavigateRegistration={onNavigateRegistration}
          />
          <Box
            sx={{
              height: '577px',
              flexBasis: '34.4%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}>
            <Box sx={{ padding: '0 0 0 142px' }}>
              <Typography
                sx={{
                  width: '260px',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                  color: COLOR_TEXT_MAIN
                }}>
                {getWord('contractor_web_intro_1')}
                <br />
                <br />
                {getWord('contractor_web_intro_2')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  my: '30px'
                }}>
                <Link href="https://play.google.com/store/apps/details?id=app.letsgoglobal.contractor" target="_blank">
                  <Box
                    component="img"
                    sx={{
                      height: 37,
                      width: 110,
                      mr: '10px'
                    }}
                    alt="googlePlay"
                    src={'/images/svg/googlePlay.svg'}
                  />
                </Link>
                <Link href="https://apps.apple.com/us/app/bocaboca-business/id6449749729" target="_blank">
                  <Box
                    component="img"
                    sx={{
                      height: 37,
                      width: 110,
                      mr: '10px'
                    }}
                    alt="appStore"
                    src={'/images/svg/appStore.svg'}
                  />
                </Link>
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </LocalizationContext.Consumer>
  );
};
