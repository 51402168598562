import React, { FC, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { OrderTab } from './components/OrderTab';
import { OrderTabType } from '../../types';
import { LocalizationContext } from '../Localization';
import {
  selectUnreadArchiveOrderMessageCount,
  selectUnreadInboxOrderMessageCount,
  selectUnreadInProgressOrderMessageCount
} from '../../app/redux/state';

interface Props {
  onClick: (value: OrderTabType) => void;
}

interface State {
  activeId: OrderTabType;
}

export const OrderTabList: FC<Props> = (props): ReactElement => {
  const unreadInboxCount = useSelector(selectUnreadInboxOrderMessageCount);
  const unreadInProgressCount = useSelector(selectUnreadInProgressOrderMessageCount);
  const unreadArchiveCount = useSelector(selectUnreadArchiveOrderMessageCount);
  const [state, setState] = useState<State>({ activeId: OrderTabType.inbox });
  const onClick = (id: OrderTabType): void => {
    setState({ activeId: id });
    props.onClick(id);
  };

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Box
          sx={{
            p: '24px 24px 21px 24px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start'
          }}>
          <OrderTab
            id={OrderTabType.inbox}
            activeId={state.activeId}
            title={getWord('contractor_inbox')}
            badge={unreadInboxCount}
            onClick={onClick}
          />
          <OrderTab
            id={OrderTabType.inProgress}
            activeId={state.activeId}
            title={getWord('contractor_in_progress')}
            badge={unreadInProgressCount}
            onClick={onClick}
          />
          <OrderTab
            id={OrderTabType.archive}
            activeId={state.activeId}
            title={getWord('contractor_archive')}
            badge={unreadArchiveCount}
            onClick={onClick}
          />
        </Box>
      )}
    </LocalizationContext.Consumer>
  );
};
