// @ts-ignore
import React, { PropsWithChildren, PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { COLOR_GREY_TEXT, COLOR_TEXT_MAIN } from '../../colors';
import { LocalizationContext } from '../Localization';

interface PropsWithChildren {
  onClick: (title: string) => void;
  title: string;
  justifyContent?: boolean;
  children?: any;
}

export class Header extends PureComponent<PropsWithChildren> {
  render() {
    const { title, children, justifyContent } = this.props;

    return (
      <LocalizationContext.Consumer>
        {({ getWord }) => (
          <>
            <Box
              sx={{
                m: '24px 30px 24px 24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: justifyContent ? 'space-between' : ''
              }}>
              <Typography
                sx={{
                  mr: '30px',
                  fontSize: '24px',
                  fontWeight: 500,
                  lineHeight: '26px',
                  color: COLOR_TEXT_MAIN
                }}>
                {getWord(title)}
              </Typography>
              {children}
            </Box>
            <Box
              sx={{
                mx: '24px',
                mb: '30px',
                height: '1px',
                backgroundColor: COLOR_GREY_TEXT
              }}
            />
          </>
        )}
      </LocalizationContext.Consumer>
    );
  }
}
