export const COLOR_GREY_BACKGROUND = '#EEF4F9';
export const COLOR_GREY_TEXT = '#BCC7D0';

export const COLOR_ORANGE = '#FF6332';
export const COLOR_WHITE = '#FFFFFF';
export const COLOR_TEXT_MAIN = '#143248';
export const COLOR_BLUE = '#008BFF';
export const COLOR_DARK_BLUE = '#143248';

export const COLOR_RED = '#FF0000';
export const COLOR_CHAT_MESSAGE = '#3B6A92';
export const COLOR_YELLOW_OPACITY_10 = '#FFB0151A';
export const COLOR_REVIEW_MESSAGE = 'rgba(255, 176, 21, 0.10)';
