import React, { FC, useState } from 'react';
import { Box, Container, TextField, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { ButtonMain } from '../../../features/ButtonMain';

import { COLOR_TEXT_MAIN } from '../../../colors';
import { LocalizationContext } from '../../../features/Localization';
interface Props {}

interface State {
  value: string;
}

export const AddService1: FC<Props> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState<State>({ value: '' });

  const onGoBack = () => navigate('/addService/0', { state: { ...location.state } });

  const onGoNext = () => navigate('/addService/2', { state: { ...location.state, description: state.value } });

  const onGoToProfile = () => navigate('/profile');

  const onChange = (e: any) => setState({ value: e.target.value });

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            p: '24px',
            height: '800px',
            width: '1440px'
          }}>
          <Box
            sx={{
              width: '754px',
              m: '0 auto'
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: '10px'
              }}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  letterSpacing: 0.46,
                  color: COLOR_TEXT_MAIN,
                  fontVariantNumeric: 'lining-nums'
                }}>
                2/3
              </Typography>
              <Box
                onClick={onGoToProfile}
                component="img"
                alt="add"
                src={'/images/svg/closeOverlay.svg'}
                sx={{
                  width: '16px',
                  height: '16px',
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
              />
            </Box>
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 500,
                letterSpacing: 0.46,
                color: COLOR_TEXT_MAIN,
                mb: '24px'
              }}>
              {getWord('contractor_add_description')}
            </Typography>
            <TextField
              onChange={onChange}
              value={state.value}
              sx={{
                '& .MuiOutlinedInput-root.Mui-focused': {
                  '& > fieldset': {
                    borderColor: '#c4c4c4',
                    borderWidth: '1px'
                  }
                },
                p: '0',
                mb: '24px'
              }}
              inputProps={{
                style: {
                  minHeight: '189px',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  letterSpacing: 0.15
                }
              }}
              id="outlined-multiline-flexible"
              label={getWord('client_description')}
              multiline
              maxRows={5}
              fullWidth={true}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: '24px'
              }}>
              <Typography
                onClick={onGoBack}
                sx={{
                  fontSize: '14px',
                  fontWeight: 600,
                  letterSpacing: 0.46,
                  color: COLOR_TEXT_MAIN,
                  ml: '22px',
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}>
                {getWord('contractor_back')}
              </Typography>
              <ButtonMain
                title={'mobile_next'}
                onClick={onGoNext}
                fontSize={'14px'}
                fontWeight={600}
                lineHeight={'20px'}
                textBoxWidth={'173px'}
              />
            </Box>
          </Box>
        </Container>
      )}
    </LocalizationContext.Consumer>
  );
};
