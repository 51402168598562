import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';

import { ButtonMain } from '../ButtonMain';
import { COLOR_TEXT_MAIN, COLOR_WHITE } from '../../colors';

interface Props {
  title: string;
  buttonTitle: string;
  onSubmit: () => void;
  onCancel: () => void;
}

export const Modal: FC<Props> = props => {
  const { title, buttonTitle, onSubmit, onCancel } = props;
  return (
    <Box>
      <Box
        sx={{
          position: 'absolute',
          top: `50%`,
          left: `50%`,
          transform: `translate(-50%, -50%)`,
          width: '350px',
          height: '150px',
          backgroundColor: COLOR_WHITE,
          zIndex: 11,
          borderRadius: '8px',
          p: '12px'
        }}>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            color: COLOR_TEXT_MAIN,
            m: '20px 0 30px 0'
          }}>
          {title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}>
          <ButtonMain
            title="Отменить"
            onClick={onCancel}
            fontSize="16px"
            fontWeight={500}
            lineHeight="18px"
            textBoxWidth="fit-content"
          />
          <ButtonMain
            title={buttonTitle}
            onClick={onSubmit}
            fontSize="16px"
            fontWeight={500}
            lineHeight="18px"
            textBoxWidth="fit-content"
          />
        </Box>
      </Box>
      <Box
        onClick={onCancel}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'darkgray',
          opacity: 0.7,
          zIndex: 10
        }}
      />
    </Box>
  );
};
