import React, { FC, ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { COLOR_TEXT_MAIN } from '../../../../colors';

import { uploadAvatar } from '../../../../app/redux/actions';
import { LocalizationContext } from '../../../Localization';

interface Props {}

export const ChangeAvatarButton: FC<Props> = (): ReactElement => {
  const dispatch = useDispatch();

  const onImageChange = (event: any) => {
    if (event.target.files != null && event.target.files.length > 0) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        dispatch(uploadAvatar(e.target.result));
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="raised-button-file"
            onChange={onImageChange}
            type="file"
            name="avatar"
          />
          <label htmlFor="raised-button-file">
            <Box
              sx={{
                height: '12px',
                p: '7px 17px',
                border: '1px solid #143248',
                borderRadius: 70,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}>
              <Typography
                sx={{
                  lineHeight: '26px',
                  fontSize: '12px',
                  fontWeight: 600,
                  letterSpacing: '0.46px',
                  color: COLOR_TEXT_MAIN
                }}>
                {getWord('contractor_change_avatar')}
              </Typography>
            </Box>
          </label>
        </>
      )}
    </LocalizationContext.Consumer>
  );
};
