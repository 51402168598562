import React, { FC } from 'react';
import { Box, Container, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { ButtonMain } from '../../features/ButtonMain';

import { COLOR_RED, COLOR_TEXT_MAIN } from '../../colors';
import { IExtendedService } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile, selectToken } from '../../app/redux/state';
import { PhotoCardProfile } from '../../features/PhotoCardProfile';
import { updateProfile } from '../../app/redux/actions';
import { LocalizationContext } from '../../features/Localization';
import { postImage } from '../../service/api';

interface Props {}

export const EditService: FC<Props> = () => {
  const params = useParams();
  const index = parseInt(params.index ?? '0', 10);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector(selectToken);
  const profile = useSelector(selectProfile);

  const onGoBack = () => navigate('/profile');

  const onChange = (e: any) => {
    const serviceList = [
      ...profile.extendedServiceList.slice(0, index),
      {
        ...profile.extendedServiceList[index],
        description: e.target.value
      },
      ...profile.extendedServiceList.slice(index + 1)
    ];
    dispatch(updateProfile({ ...profile, extendedServiceList: [...serviceList] }, false));
  };

  const onDelete = () => {
    const serviceList = [
      ...profile.extendedServiceList.slice(0, index),
      ...profile.extendedServiceList.slice(index + 1)
    ];
    dispatch(updateProfile({ ...profile, extendedServiceList: [...serviceList] }, false));
  };

  const onImageChange = (event: any) => {
    if (token != null && event.target.files != null && event.target.files.length > 0) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        postImage(token, [e.target.result])
          .then(data => {
            if (Array.isArray(data)) {
              const serviceList = [
                ...profile.extendedServiceList.slice(0, index),
                {
                  ...profile.extendedServiceList[index],
                  imageUrlList: [...profile.extendedServiceList[index].imageUrlList, ...data]
                },
                ...profile.extendedServiceList.slice(index + 1)
              ];
              dispatch(updateProfile({ ...profile, extendedServiceList: [...serviceList] }, false));
            }
          })
          .catch(console.log);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const onSave = () => {
    dispatch(updateProfile({ ...profile }, true));
    navigate('/profile');
  };

  const service: IExtendedService = profile.extendedServiceList[index];

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            p: '24px',
            height: '800px',
            width: '1440px'
          }}>
          <Box
            sx={{
              width: '754px',
              m: '0 auto'
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: '24px'
              }}>
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: 500,
                  lineHeight: '26px',
                  color: COLOR_TEXT_MAIN,
                  letterSpacing: 0.46
                }}>
                {getWord('contractor_edit')}
              </Typography>
              <Box
                onClick={onGoBack}
                component="img"
                alt="overlay"
                src={'/images/svg/closeOverlay.svg'}
                sx={{
                  width: '16px',
                  height: '16px',
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
              />
            </Box>
            <TextField
              onChange={onChange}
              value={service.description}
              sx={{
                '& .MuiOutlinedInput-root.Mui-focused': {
                  '& > fieldset': {
                    borderColor: '#c4c4c4',
                    borderWidth: '1px'
                  }
                },
                mb: '24px'
              }}
              inputProps={{
                style: {
                  minHeight: '128px',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  letterSpacing: 0.15
                }
              }}
              id="outlined-multiline-flexible"
              label={getWord('client_description')}
              multiline
              maxRows={4}
              fullWidth={true}
            />
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                mb: '24px'
              }}>
              {service.imageUrlList.map((url, index) => (
                <PhotoCardProfile key={`PhotoCardProfile-${index}`} src={url} />
              ))}
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                onChange={onImageChange}
                type="file"
                name="avatar"
              />
              <label htmlFor="raised-button-file">
                <Box component="img" src={'/images/svg/addPhoto.svg'} />
              </label>
            </Box>
            <Box
              sx={{
                height: '36px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              <Box
                onClick={onDelete}
                sx={{
                  ml: '22px',
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}>
                <Box
                  component="img"
                  alt="decline"
                  src={'/images/svg/decline.svg'}
                  sx={{
                    mr: '6px',
                    width: '14px',
                    height: '14px'
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '26px',
                    color: COLOR_RED,
                    letterSpacing: 0.46
                  }}>
                  {getWord('contractor_delete_service')}
                </Typography>
              </Box>
              <ButtonMain
                title={'contractor_save_changes'}
                onClick={onSave}
                fontSize={'14px'}
                fontWeight={600}
                lineHeight={'20px'}
                textBoxWidth={'173px'}
              />
            </Box>
          </Box>
        </Container>
      )}
    </LocalizationContext.Consumer>
  );
};
