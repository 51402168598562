import { Box } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {}

export const LogoMain: FC<Props> = () => {
  const navigate = useNavigate();
  const onClick = () => navigate('/');

  return (
    <Box
      sx={{
        height: '48.7px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&:hover': {
          cursor: 'pointer'
        }
      }}
      onClick={onClick}>
      <Box
        component="img"
        sx={{
          width: 71,
          height: 47
        }}
        alt="logo"
        src={`/images/svg/boca-boca.svg`}
      />
    </Box>
  );
};
