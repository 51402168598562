import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { selectToken } from '../../app/redux/state';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const token = useSelector(selectToken);

  if (token == null) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};
