import React, { FC, useEffect, useRef, useState } from 'react';
import { LocalizationContext } from '../Localization';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { has, isEqual } from 'lodash';
import { Box, Typography } from '@mui/material';

import { selectProfile } from '../../app/redux/state';
import { updateProfile } from '../../app/redux/actions';
import { COLOR_GREY_BACKGROUND, COLOR_TEXT_MAIN, COLOR_WHITE } from '../../colors';

const cLanguageList = {
  Español: {
    title: 'Español',
    src: '/images/flag-es.png'
  },
  Català: {
    title: 'Català',
    src: '/images/flag-ca.jpeg'
  },
  English: {
    title: 'English',
    src: '/images/flag-en.png'
  },
  Русский: {
    title: 'Русский',
    src: '/images/flag-ru.png'
  },
  Deutsch: {
    title: 'Deutsch',
    src: '/images/flag-de.png'
  },
  Italiano: {
    title: 'Italiano',
    src: '/images/flag-it.png'
  },
  Français: {
    title: 'Français',
    src: '/images/flag-fr.png'
  },
  Português: {
    title: 'Português',
    src: '/images/flag-pt.png'
  },
  Український: {
    title: 'Український',
    src: '/images/flag-uk.png'
  },
  汉语: {
    title: '汉语',
    src: '/images/flag-zh.png'
  },
  עברית: {
    title: 'עברית',
    src: '/images/flag-he.png'
  }
};

interface Props {
  needSaveProfile: boolean;
}

export const CustomSelectLanguages: FC<Props> = ({ needSaveProfile }: Props) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>(
    has(profile, 'serviceLanguages') ? [...profile.serviceLanguages] : []
  );
  const [inputHeight, setInputHeight] = useState(56);

  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      // @ts-ignore
      setInputHeight(ref.current?.clientHeight + 2);
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, []);

  const onOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!isEqual(selectedLanguages, profile.serviceLanguages)) {
      dispatch(updateProfile({ ...profile, serviceLanguages: [...selectedLanguages] }, needSaveProfile));
    }
  }, [selectedLanguages]);
  const onSelectLanguage = (value: string) => {
    if (!selectedLanguages.some(language => language === value)) {
      setSelectedLanguages([...selectedLanguages, value]);
    }
  };
  const onDeleteLanguage = (value: string) => {
    const serviceLanguages = selectedLanguages.filter(language => language !== value);
    setSelectedLanguages(serviceLanguages);
  };

  return (
    <>
      <LocalizationContext.Consumer>
        {({ getWord }) =>
          selectedLanguages.length === 0 ? (
            <Box
              ref={ref}
              onClick={onOpen}
              sx={{
                position: 'relative',
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap',
                borderRadius: '4px',
                paddingHorizontal: 12,
                border: '1px solid #cbcbcb',
                marginTop: '15px',
                minHeight: '54px',
                '&:hover': {
                  border: '1px solid #000000'
                }
              }}>
              <Typography
                sx={{
                  p: '0 16px 0 5px',
                  backgroundColor: COLOR_WHITE,
                  position: 'absolute',
                  top: -9,
                  left: '10px',
                  fontStyle: 'normal',
                  fontSize: '10px',
                  fontWeight: 400,
                  lineHeight: '10px',
                  color: '#00000099',
                  fontFamily: 'Roboto',
                  letterSpacing: 0.00938
                }}>
                {getWord('contractor_i_speak')}
              </Typography>
              {isOpen ? (
                <ArrowDropUpIcon
                  onClick={onOpen}
                  sx={{
                    width: '35px',
                    color: '#838282',
                    position: 'absolute',
                    right: '6px',
                    top: '16px',
                    '&:hover': {
                      cursor: 'pointer'
                    }
                  }}
                />
              ) : (
                <ArrowDropDownIcon
                  onClick={onOpen}
                  sx={{
                    width: '35px',
                    color: '#838282',
                    position: 'absolute',
                    right: '6px',
                    top: '16px',
                    '&:hover': {
                      cursor: 'pointer'
                    }
                  }}
                />
              )}
              {isOpen && (
                <Box
                  onClick={e => e.stopPropagation()}
                  sx={{
                    p: '5px 0 0 0',
                    width: '100.4%',
                    position: 'absolute',
                    top: `${inputHeight}px`,
                    left: -1,
                    maxHeight: '200px',
                    overflowY: 'auto',
                    backgroundColor: COLOR_WHITE,
                    zIndex: 2,
                    boxShadow: '-3px 2px 10px #d1d1d7',
                    borderRadius: '4px'
                  }}>
                  {Object.values(cLanguageList).map(l => (
                    <Box
                      key={l.title}
                      onClick={() => onSelectLanguage(l.title)}
                      sx={{
                        p: '4px 12px',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: COLOR_WHITE,
                        borderRadius: '100px',
                        marginRight: '8px',
                        marginBottom: '8px',
                        zIndex: 3,
                        '&:hover': { cursor: 'pointer' }
                      }}>
                      <Box
                        component="img"
                        sx={{
                          height: '12px',
                          width: '16px',
                          mr: '10px'
                        }}
                        alt="flag"
                        src={l.src}
                      />
                      <Typography
                        sx={{
                          fontStyle: 'normal',
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '18px',
                          letterSpacing: 0.16,
                          color: COLOR_TEXT_MAIN,
                          marginLeft: '5px',
                          marginRight: '5px'
                        }}>
                        {l.title}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          ) : (
            <Box
              ref={ref}
              sx={{
                position: 'relative',
                display: 'flex',
                flexWrap: 'wrap',
                borderRadius: '4px',
                border: '1px solid #cbcbcb',
                gap: '8px',
                marginTop: '15px',
                p: '15px 20px 15px 15px'
              }}>
              <Typography
                sx={{
                  p: '0 16px 0 5px',
                  backgroundColor: COLOR_WHITE,
                  position: 'absolute',
                  top: -9,
                  left: '10px',
                  fontStyle: 'normal',
                  fontSize: '10px',
                  fontWeight: 400,
                  lineHeight: '10px',
                  color: '#00000099',
                  fontFamily: 'Roboto',
                  letterSpacing: 0.00938
                }}>
                {getWord('contractor_i_speak')}
              </Typography>
              {selectedLanguages.map(l => (
                <Box
                  sx={{
                    width: 'fit-content',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: COLOR_GREY_BACKGROUND,
                    p: '3px 4px 3px 10px',
                    height: '18px',
                    borderRadius: '100px',
                    '&:hover': { cursor: 'pointer' }
                  }}
                  onClick={() => onDeleteLanguage(l)}
                  key={l}>
                  <Box
                    component="img"
                    sx={{
                      height: '12px',
                      width: '16px',
                      mr: '4px',
                      objectFit: 'cover'
                    }}
                    alt="flag"
                    // @ts-ignore
                    src={cLanguageList[l].src}
                  />
                  <Typography
                    sx={{
                      fontStyle: 'normal',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '18px',
                      letterSpacing: 0.16,
                      color: COLOR_TEXT_MAIN,
                      marginRight: '5px'
                    }}
                    key={l}>
                    {l}
                  </Typography>
                  <Box
                    component="img"
                    sx={{
                      height: '16px',
                      width: '16px'
                    }}
                    alt="delete"
                    src={'/images/svg/cancel.svg'}
                  />
                </Box>
              ))}
              {isOpen ? (
                <ArrowDropUpIcon
                  onClick={onOpen}
                  sx={{
                    color: '#838282',
                    width: '35px',
                    position: 'absolute',
                    right: '6px',
                    top: '16px',
                    '&:hover': {
                      cursor: 'pointer'
                    }
                  }}
                />
              ) : (
                <ArrowDropDownIcon
                  onClick={onOpen}
                  sx={{
                    color: '#838282',
                    width: '35px',
                    position: 'absolute',
                    right: '6px',
                    top: '16px',
                    '&:hover': {
                      cursor: 'pointer'
                    }
                  }}
                />
              )}
              {isOpen && (
                <Box
                  sx={{
                    p: '5px 0 0 0',
                    width: '100.4%',
                    position: 'absolute',
                    top: `${inputHeight}px`,
                    left: -1,
                    maxHeight: '200px',
                    overflowY: 'auto',
                    backgroundColor: COLOR_WHITE,
                    zIndex: 10,
                    boxShadow: '-3px 2px 10px #d1d1d7',
                    borderRadius: '4px'
                  }}>
                  {Object.values(cLanguageList).map(l => (
                    <Box
                      key={l.title}
                      onClick={() => onSelectLanguage(l.title)}
                      sx={{
                        p: '4px 12px',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: COLOR_WHITE,
                        borderRadius: '100px',
                        marginRight: '8px',
                        marginBottom: '8px',
                        '&:hover': { cursor: 'pointer' }
                      }}>
                      <Box
                        component="img"
                        sx={{
                          height: '12px',
                          width: '16px',
                          mr: '10px'
                        }}
                        alt="flag"
                        src={l.src}
                      />
                      <Typography
                        sx={{
                          fontStyle: 'normal',
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '18px',
                          letterSpacing: 0.16,
                          color: COLOR_TEXT_MAIN,
                          marginLeft: '5px',
                          marginRight: '5px'
                        }}>
                        {l.title}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          )
        }
      </LocalizationContext.Consumer>
    </>
  );
};
