import React, { FC } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Box, Typography } from '@mui/material';

import { LocalizationContext } from '../../../Localization';
import { COLOR_TEXT_MAIN } from '../../../../colors';

interface Props {
  onSuccess: (value: any) => void;
  title: string;
}

export const CustomSignGoogleButton: FC<Props> = ({ onSuccess, title }: Props) => {
  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: tokenResponse => onSuccess(tokenResponse)
  });
  return (
    <Box
      sx={{
        p: '8px 22px',
        border: '1px solid #143248',
        borderRadius: 70,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mb: '18px',
        '&:hover': {
          cursor: 'pointer'
        }
      }}
      onClick={() => login()}>
      <Box
        component="img"
        sx={{
          width: '20px',
          height: '20px',
          mr: '10px'
        }}
        src={'/images/svg/google.svg'}
        alt="lets`s do"
      />
      <LocalizationContext.Consumer>
        {({ getWord }) => (
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '36px',
              fontWeight: 500,
              letterSpacing: 0.46,
              color: COLOR_TEXT_MAIN
            }}>
            {getWord(title)}
          </Typography>
        )}
      </LocalizationContext.Consumer>
    </Box>
  );
};
