import React, { FC, ReactElement, useEffect, useRef } from 'react';
import { Box } from '@mui/material';

import { IncomingMessage } from '../IncomingMessage';
import { OutgoingMessage } from '../OutgoingMessage';

import { IChatMessage, IReview } from '../../../types';
import { ReviewMessage } from '../ReviewMessage';

interface Props {
  data: IChatMessage[];
  review: IReview | null;
}

export const ChatBody: FC<Props> = ({ data, review }: Props): ReactElement => {
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    // @ts-ignore
    messagesEndRef.current?.scrollIntoView();
  };
  useEffect(() => {
    scrollToBottom();
  }, [data]);

  return (
    <Box
      sx={{
        pt: '12px',
        height: 'calc(100vh - 45px - 114px - 56px)',
        p: '0 24px',
        overflowY: 'auto'
      }}>
      {/*<DateAndTime date={'9:12, today'} />*/}
      {data.map(msg =>
        msg.root ? (
          <IncomingMessage key={`IncomingMessage-${msg.id}`} message={msg.value} />
        ) : (
          <OutgoingMessage key={`OutgoingMessage-${msg.id}`} message={msg.value} />
        )
      )}
      {review != null && <ReviewMessage message={review.review} stars={review.starCount} />}
      <Box ref={messagesEndRef} />
    </Box>
  );
};
