import React, { FC, ReactElement } from 'react';
import { Box, Link, Typography } from '@mui/material';

import { COLOR_TEXT_MAIN, COLOR_WHITE } from '../../../../colors';

import env from '../../../../environment.json';
import { LocalizationContext } from '../../../../features/Localization';

interface Props {
  isChecked: boolean;
  handleChecked: () => void;
}

export const CheckBox: FC<Props> = (props): ReactElement => {
  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Box
          sx={{
            backgroundColor: COLOR_WHITE,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}>
          {props.isChecked ? (
            <Box
              onClick={props.handleChecked}
              component="img"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
                border: '2px solid #00000099',
                width: '22px',
                height: '22px',
                mx: '9px'
              }}
              src={'/images/svg/checkIcon.svg'}
            />
          ) : (
            <Box
              onClick={props.handleChecked}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
                border: '2px solid #00000099',
                width: '22px',
                height: '22px',
                mx: '9px'
              }}
            />
          )}
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              letterSpacing: 0.15,
              color: COLOR_TEXT_MAIN,
              mr: '3px'
            }}>
            {getWord('contractor_i_agree_to')}
          </Typography>
          <Link
            underline="always"
            sx={{
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              color: COLOR_TEXT_MAIN,
              lineHeight: '24px',
              textDecorationColor: COLOR_TEXT_MAIN
            }}
            href={env.policyURL}>
            {getWord('contractor_terms_check')}
          </Link>
        </Box>
      )}
    </LocalizationContext.Consumer>
  );
};
