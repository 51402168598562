import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { COLOR_TEXT_MAIN, COLOR_WHITE } from '../../colors';
import { LocalizationContext } from '../Localization';

interface Props {
  title: string;
  onClick: () => void;
  fontSize: string;
  fontWeight: number;
  lineHeight: string;
  textBoxWidth: string;
}

export class ButtonMain extends PureComponent<Props> {
  render() {
    const { title, onClick, fontSize, fontWeight, lineHeight, textBoxWidth } = this.props;
    return (
      <LocalizationContext.Consumer>
        {({ getWord }) => (
          <Box
            sx={{
              // width: textBoxWidth,
              p: '7px 21px',
              border: '1px solid #143248',
              borderRadius: 70,
              '&:hover': {
                cursor: 'pointer'
              },
              backgroundColor: COLOR_WHITE
            }}
            onClick={onClick}>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: fontSize,
                lineHeight: lineHeight,
                fontWeight: fontWeight,
                letterSpacing: 0.46,
                color: COLOR_TEXT_MAIN
              }}>
              {getWord(title)}
            </Typography>
          </Box>
        )}
      </LocalizationContext.Consumer>
    );
  }
}
