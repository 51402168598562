import { Box, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { COLOR_TEXT_MAIN, COLOR_ORANGE, COLOR_WHITE } from '../../../../colors';

import { selectUnreadMessageCount } from '../../../../app/redux/state';
import { LocalizationContext } from '../../../Localization';

interface Props {}

export const TaskBoard: FC<Props> = (): ReactElement => {
  const navigate = useNavigate();
  const unreadMessagesCount = useSelector(selectUnreadMessageCount);
  const navigateToTaskBoard = () =>
    navigate('/', {
      replace: true
    });

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: '30px',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={navigateToTaskBoard}>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 600,
              color: COLOR_TEXT_MAIN,
              mb: '10px'
            }}>
            {getWord('contractor_taskboard')}
          </Typography>
          <Box
            sx={{
              width: unreadMessagesCount > 0 ? '38px' : '21px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              position: 'relative'
            }}>
            <Box component="img" src={'images/svg/unreadMessages.svg'} sx={{ width: '21px', height: '20px' }} />
            {unreadMessagesCount > 0 && (
              <Box
                sx={{
                  position: 'absolute',
                  left: '16px',
                  backgroundColor: COLOR_ORANGE,
                  border: '1px solid white',
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <Typography
                  sx={{
                    fontVariantNumeric: 'lining-nums',
                    color: COLOR_WHITE,
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 600
                  }}>
                  {unreadMessagesCount}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </LocalizationContext.Consumer>
  );
};
