import React, { FC, ReactElement, useState } from 'react';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { selectProfile } from '../../../../app/redux/state';
import { updateProfile } from '../../../../app/redux/actions';
import { LocalizationContext } from '../../../Localization';

interface Props {
  needSaveProfile: boolean;
}

export const FullNameInput: FC<Props> = ({ needSaveProfile }: Props): ReactElement => {
  const profile = useSelector(selectProfile);
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState(profile.fullName ?? '');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setFullName(event.target.value);
  const onBlur = () => {
    if (fullName !== profile.fullName) dispatch(updateProfile({ ...profile, fullName }, needSaveProfile));
  };

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <TextField
          onChange={onChange}
          onBlur={onBlur}
          id="outlined-basic"
          label={getWord('client_name')}
          variant="outlined"
          fullWidth={true}
          value={fullName}
          sx={{
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: '#c4c4c4',
                borderWidth: '1px'
              },
              height: '56px'
            },
            height: '56px',
            mb: '16px'
          }}
          inputProps={{
            style: {
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: 0.15,
              height: '24px'
            }
          }}
        />
      )}
    </LocalizationContext.Consumer>
  );
};
