import React, { FC, useState } from 'react';
import { Typography } from '@mui/material';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Modal } from '../Modal';

import { COLOR_GREY_TEXT } from '../../colors';

import { logout } from '../../app/redux/actions';
import { LocalizationContext } from '../Localization';

interface Props {}

export const DeleteAccountButton: FC<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleModal = () => setIsModalOpen(!isModalOpen);

  const onDeleteAccount = () => {
    dispatch(logout(true));
    navigate('/');
  };

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <>
          <Typography
            onClick={handleModal}
            sx={{
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '14px',
              color: COLOR_GREY_TEXT,
              my: '10px',
              '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline'
              }
            }}>
            {getWord('contractor_delete_profile')}
          </Typography>
          {isModalOpen && (
            <Modal
              title={'Удалить аккаунт?'}
              buttonTitle={'Удалить'}
              onSubmit={onDeleteAccount}
              onCancel={handleModal}
            />
          )}
        </>
      )}
    </LocalizationContext.Consumer>
  );
};
