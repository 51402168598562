import React, { FC, ReactElement } from 'react';
import { Box, Typography } from '@mui/material';

import { COLOR_TEXT_MAIN, COLOR_WHITE } from '../../../../colors';

import { IService } from '../../../../types';
import { has } from 'lodash';

interface Props {
  service: IService;
  checkedId: Record<number, boolean>;
  onClick: (value: number) => void;
}

export const ServiceCheckbox: FC<Props> = ({ service, checkedId, onClick }): ReactElement => {
  const isChecked = () => has(checkedId, service.id) && checkedId[service.id];
  const onClickCheckbox = () => onClick(service.id);
  return (
    <Box
      onClick={onClickCheckbox}
      key={service.id}
      sx={{
        backgroundColor: COLOR_WHITE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        p: ' 0 16px 10px'
      }}>
      <Typography
        sx={{
          minWidth: '500px',
          fontSize: '15px',
          fontWeight: 400,
          letterSpacing: 0.15,
          color: COLOR_TEXT_MAIN
        }}>
        {service.name}
      </Typography>
      {isChecked() ? (
        <Box
          component="img"
          sx={{
            borderRadius: '4px',
            border: '2px solid #00000099',
            maxWidth: '22px',
            height: '22px',
            ml: '8px'
          }}
          src={'/images/svg/checkIcon.svg'}
        />
      ) : (
        <Box
          sx={{
            borderRadius: '4px',
            border: '2px solid #00000099',
            width: '22px',
            height: '22px',
            ml: '8px'
          }}
        />
      )}
    </Box>
  );
};
