import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import appReducer from './reducer';
import { appSaga } from './saga';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

const rootReducer = combineReducers({ app: appReducer });

export default function* rootSaga() {
  yield all([...appSaga]);
}

export const getStore = (): ToolkitStore => {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({}).concat(sagaMiddleware),
    devTools: process.env.NODE_ENV !== 'production'
  });
  sagaMiddleware.run(rootSaga);
  return store;
};
