import { createSelector } from 'reselect';

import { IOrder, IPage, IService, IChatMessage, OrderTabType, IProfile, ICountry } from '../../types';

export const initialProfile: IProfile = {
  version: 0,
  id: null,
  hasGoogleCredentials: false,
  avatar: null,
  avatarURL: null,
  fullName: null,
  email: null,
  phone: null,
  language: null,
  serviceLanguages: [],
  country: null,
  city: null,
  info: '',
  serviceList: [],
  review: {
    starCount: 0,
    reviewCount: 0
  },
  approved: false,
  gallery: [],
  mode: null,
  extendedServiceList: []
};

export interface IAppState {
  nickname: string;
  token: string | null;
  userID: number | null;
  categoryList: { id: number; value: string }[];
  serviceList: IService[];
  service: IPage | null;
  payload: { name: string | null; surname: string | null; avatar: string | null };
  profile: IProfile;
  unreadChatMessageCount: number;
  unreadInboxOrderMessageCount: number;
  unreadInProgressOrderMessageCount: number;
  unreadArchiveOrderMessageCount: number;
  orderList: Record<OrderTabType, IOrder[]>;
  messageList: IChatMessage[];
  lastMessage: IChatMessage | null;
  countryList: ICountry[];
  phrase: string | null;
  phraseServiceList: IService[];
}

export const initialState: IAppState = {
  nickname: '',
  token: null,
  userID: null,
  categoryList: [],
  serviceList: [],
  service: null,
  payload: { name: null, surname: null, avatar: null },
  profile: initialProfile,
  unreadChatMessageCount: 0,
  unreadInboxOrderMessageCount: 0,
  unreadInProgressOrderMessageCount: 0,
  unreadArchiveOrderMessageCount: 0,
  orderList: { [OrderTabType.inbox]: [], [OrderTabType.inProgress]: [], [OrderTabType.archive]: [] },
  messageList: [],
  lastMessage: null,
  countryList: [],
  phrase: null,
  phraseServiceList: []
};

export const selectState = (state: { app: IAppState }) => state.app;

export const selectToken = createSelector(selectState, state => state.token);

export const selectUserID = createSelector(selectState, state => state.userID);

export const selectCategoryList = createSelector(selectState, state => state.categoryList);

export const selectServiceList = createSelector(selectState, state => state.serviceList);

export const selectService = createSelector(selectState, state => state.service);

export const selectPayload = createSelector(selectState, state => state.payload);

export const selectProfile = createSelector(selectState, state => state.profile);
export const selectUnreadInboxOrderMessageCount = createSelector(
  selectState,
  state => state.unreadChatMessageCount + state.unreadInboxOrderMessageCount
);
export const selectUnreadInProgressOrderMessageCount = createSelector(
  selectState,
  state => state.unreadInProgressOrderMessageCount
);
export const selectUnreadArchiveOrderMessageCount = createSelector(
  selectState,
  state => state.unreadArchiveOrderMessageCount
);
export const selectUnreadMessageCount = createSelector(
  selectState,
  state =>
    +state.unreadChatMessageCount +
    state.unreadInboxOrderMessageCount +
    state.unreadInProgressOrderMessageCount +
    state.unreadArchiveOrderMessageCount
);
export const selectOrderList = createSelector(selectState, state => state.orderList);
export const selectMessageList = createSelector(selectState, state => state.messageList);
export const selectLastMessage = createSelector(selectState, state => state.lastMessage);
export const selectCountryList = createSelector(selectState, state => state.countryList);
export const selectPhrase = createSelector(selectState, state => state.phrase);
export const selectPhraseServiceList = createSelector(selectState, state => state.phraseServiceList);
