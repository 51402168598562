import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

import { LocalizationContext } from '../Localization';

import { changeLanguage } from '../../app/redux/actions';

interface Props {}

export const ChooseAppLanguageSidebar: FC<Props> = (_props: Props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);

  const onChange = (language: string) => {
    // changeLanguage(value);
    setOpen(!open);
    dispatch(changeLanguage(language));
  };

  return (
    <LocalizationContext.Consumer>
      {({ language, languageList, changeLanguage }) => (
        <>
          <Box
            onClick={() => setOpen(!open)}
            component="img"
            sx={{
              height: 20,
              width: 20,
              borderRadius: '50%',
              objectFit: 'cover',
              '&:hover': {
                cursor: 'pointer'
              }
            }}
            alt="avatar"
            src={language.img}
          />
          {open && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                position: 'absolute',
                top: 0,
                left: 27,
                backgroundColor: 'transparent',
                zIndex: 10
              }}>
              {Object.values(languageList).map(({ value, img }) =>
                value !== language.value ? (
                  <Box
                    onClick={() => {
                      changeLanguage(value);
                      onChange(value);
                    }}
                    key={value}
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      mr: '7px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                      '&:hover': {
                        cursor: 'pointer'
                      }
                    }}
                    alt="avatar"
                    src={img}
                  />
                ) : null
              )}
            </Box>
          )}
        </>
      )}
    </LocalizationContext.Consumer>
  );
};
