import React, { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { MessageInput } from './MessageInput';

import { selectLastMessage, selectMessageList, selectOrderList } from '../../app/redux/state';

import { COLOR_GREY_TEXT } from '../../colors';
import { ChatBody } from './ChatBody';
import { ChatOrderHeader } from './ChatOrderHeader';
import { ChatAdminHeader } from './ChatAdminHeader';
import { OrderTabType } from '../../types';
import { acceptOrder, completeOrder } from '../../app/redux/actions';

interface Props {
  activeId: OrderTabType;
  activeIndex: number;
}

export const Chat: FC<Props> = (props): ReactElement | null => {
  const dispatch = useDispatch();
  const orderList = useSelector(selectOrderList);

  const { activeIndex } = props;
  const order =
    activeIndex < 0 || activeIndex >= orderList[props.activeId].length ? null : orderList[props.activeId][activeIndex];
  const lastMessage = useSelector(selectLastMessage);
  const messageList = useSelector(selectMessageList);

  const onAcceptOrder = () => order != null && dispatch(acceptOrder(order.id));

  const onCompleteOrder = () => order != null && dispatch(completeOrder(order.id));

  return (
    <Box sx={{ flexBasis: '850px', flexGrow: 2, flexShrink: 1, minWidth: '850px' }}>
      {order != null && <ChatOrderHeader order={order} onAccept={onAcceptOrder} onComplete={onCompleteOrder} />}
      {order == null && lastMessage != null && <ChatAdminHeader />}
      <Box
        sx={{
          mx: '24px',
          height: '1px',
          backgroundColor: COLOR_GREY_TEXT
        }}
      />
      {order != null && (
        <>
          <ChatBody data={order.data} review={order.review} />
          <MessageInput order={order} />
        </>
      )}
      {order == null && lastMessage != null && (
        <>
          <ChatBody data={messageList} review={null} />
          <MessageInput order={order} />
        </>
      )}
    </Box>
  );
};
