import React, { FC, ReactElement } from 'react';
import { Box, Typography } from '@mui/material';

import { COLOR_GREY_BACKGROUND, COLOR_TEXT_MAIN } from '../../../colors';

interface Props {
  message: string;
}

export const IncomingMessage: FC<Props> = ({ message }): ReactElement => (
  <Box
    sx={{
      borderRadius: '8px',
      backgroundColor: COLOR_GREY_BACKGROUND,
      p: '12px',
      width: 'fit-content',
      maxWidth: '660px',
      mb: '12px'
    }}>
    <Typography sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '23px', color: COLOR_TEXT_MAIN }}>
      {message}
    </Typography>
  </Box>
);
