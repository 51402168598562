import { Box, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { COLOR_TEXT_MAIN, COLOR_ORANGE, COLOR_WHITE, COLOR_GREY_BACKGROUND } from '../../../../colors';

import { selectProfile } from '../../../../app/redux/state';

import env from '../../../../environment.json';

interface Props {}

export const UserInfo: FC<Props> = (): ReactElement => {
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);

  const navigateToProfile = () =>
    navigate('/profile', {
      replace: true
    });

  //@todo profile with firstname & surname
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        py: '29px',
        '&:hover': {
          cursor: 'pointer'
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 24,
          width: '94px',
          height: '1px',
          backgroundColor: COLOR_GREY_BACKGROUND
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 24,
          width: '94px',
          height: '1px',
          backgroundColor: COLOR_GREY_BACKGROUND
        }
      }}
      onClick={navigateToProfile}>
      <Typography
        sx={{
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 600,
          color: COLOR_TEXT_MAIN,
          textAlign: 'center'
        }}>
        {profile.fullName != null && profile.fullName.split(' ')[0]}
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 600,
          color: COLOR_TEXT_MAIN,
          mb: '19px',
          textAlign: 'center'
        }}>
        {profile.fullName != null && profile.fullName.split(' ')[1]}
      </Typography>
      <Box
        sx={{
          backgroundColor: profile.avatarURL != null ? 'transparent' : COLOR_ORANGE,
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: '19px',
          '&:hover': {
            cursor: 'pointer'
          }
        }}>
        {profile.avatarURL != null ? (
          <Box
            component={'img'}
            sx={{
              width: '60px',
              height: '60px',
              borderRadius: '50%',
              objectFit: 'cover'
            }}
            //@ts-ignore
            src={`${env[env.environment].host}/${profile.avatarURL}`}
          />
        ) : (
          <Typography
            sx={{
              color: COLOR_WHITE
            }}>
            {profile.fullName != null && profile.fullName.split(' ')[0] ? profile.fullName.split(' ')[0][0] : 'A'}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          mb: '19px',
          '&:hover': {
            cursor: 'pointer'
          }
        }}>
        <Box
          component="img"
          sx={{
            height: 12,
            width: 14
          }}
          alt="star"
          src={`/images/svg/star.svg`}
        />
        <Typography
          sx={{
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            color: COLOR_TEXT_MAIN,
            textAlign: 'center'
          }}>
          {profile.review.starCount}
        </Typography>
      </Box>
      <Box
        sx={{
          '&:hover': {
            cursor: 'pointer'
          }
        }}>
        <Box
          component="img"
          sx={{
            height: 12,
            width: 14
          }}
          alt="comments"
          src={`/images/svg/comments.svg`}
        />
        <Typography
          sx={{
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            color: COLOR_TEXT_MAIN,
            textAlign: 'center'
          }}>
          {profile.review.reviewCount}
        </Typography>
      </Box>
    </Box>
  );
};
