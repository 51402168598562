import React, { FC } from 'react';
import { Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { SideBar } from '../../features/SideBar';
import { Header } from '../../features/Header';
import { ServiceViewProfile } from '../../features/ServiceViewProfile';
import { ProfileInfo } from '../../features/ProfileInfo';

import { COLOR_GREY_BACKGROUND } from '../../colors';
import { useSelector } from 'react-redux';
import { has } from 'lodash';

import { selectProfile } from '../../app/redux/state';
import { IExtendedService } from '../../types';

interface Props {}

export const Profile: FC<Props> = () => {
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);

  const onEditService = (index: number) => navigate(`/edit/${index}`);

  const onAddService = () => navigate(`/addService/0`);

  const extendedServiceList: IExtendedService[] = has(profile, 'extendedServiceList')
    ? profile.extendedServiceList
    : [];
  return (
    <Container
      maxWidth={false}
      style={{
        width: '100vw',
        height: '100vh',
        padding: 0,
        display: 'flex',
        flexDirection: 'row'
      }}>
      <SideBar />
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Box
          sx={{
            backgroundColor: COLOR_GREY_BACKGROUND,
            // minWidth: '802px',
            mt: '12px',
            borderRadius: '6px 6px 0 0 ',
            overflowY: 'auto',
            flexBasis: '802px',
            flexGrow: 2,
            flexShrink: 1
          }}>
          <Header onClick={() => {}} title={'contractor_my_services'}>
            <Box
              sx={{
                p: '7px 21px',
                border: '1px solid #143248',
                borderRadius: 70,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}>
              <Box
                onClick={onAddService}
                component="img"
                alt="add"
                src={'/images/svg/add.svg'}
                sx={{
                  width: '20px',
                  height: '20px'
                }}
              />
            </Box>
          </Header>
          {extendedServiceList.map((s, index) => (
            <ServiceViewProfile
              key={`service-${index}`}
              service={s}
              canEdit={true}
              isMorePhoto={false}
              onEditService={() => onEditService(index)}
            />
          ))}
        </Box>
        <ProfileInfo />
      </Box>
    </Container>
  );
};
