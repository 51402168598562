import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { has } from 'lodash';
import { useSelector } from 'react-redux';

import { ButtonMain } from '../../../features/ButtonMain';
import { ButtonDarkBlue } from '../../../features/ButtonDarkBlue';
import { Search } from '../../../features/CollapseCategory/components/Search';
import { CollapseCategory } from '../../../features/CollapseCategory';

import { COLOR_TEXT_MAIN } from '../../../colors';

import { selectCategoryList, selectPhraseServiceList } from '../../../app/redux/state';
import { LocalizationContext } from '../../../features/Localization';
import { LogoMain } from '../../../features/LogoMain';
import { ServiceCheckbox } from '../../../features/CollapseCategory/components/ServiceCheckbox';

interface Props {}
interface State {
  checkedServiceId: Record<string, boolean>;
}

interface Props {}

export const SignUp1: FC<Props> = (): ReactElement => {
  const navigate = useNavigate();
  const phraseServiceList = useSelector(selectPhraseServiceList);
  const categoryList = useSelector(selectCategoryList);
  const [state, setState] = useState<State>({
    checkedServiceId: {}
  });

  useEffect(() => {
    console.log(window.innerHeight);
  }, [window.innerHeight]);

  const onChecked = (id: number) =>
    setState({
      checkedServiceId: {
        ...state.checkedServiceId,
        [id]: has(state.checkedServiceId, id) ? !state.checkedServiceId[id] : true
      }
    });

  const onNext = () =>
    navigate('/signUp/2', {
      state: {
        checkedServiceId: Object.keys(state.checkedServiceId).reduce((prev: string[], key: string) => {
          if (checkedServiceId[key]) {
            prev.push(key);
          }
          return prev;
        }, [] as string[])
      }
    });

  const onBack = () => navigate('/signUp/0');

  const isCanGoNext = () => true;

  const { checkedServiceId } = state;

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', ml: '35px', mt: '30px' }}>
            <LogoMain />
          </Box>
          <Box
            sx={{
              position: 'relative',
              float: 'right',
              width: '566px'
            }}>
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '26px',
                color: COLOR_TEXT_MAIN,
                mb: '53px'
              }}>
              {getWord('contractor_signup')}
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 400,
                letterSpacing: 0.46,
                color: COLOR_TEXT_MAIN,
                mt: '53px',
                mb: '16px'
              }}>
              {getWord('contractor_select_services')}
            </Typography>
            <Search />
            <Box sx={{ mb: '115px' }}>
              {phraseServiceList.length === 0
                ? categoryList.map(category => (
                    <CollapseCategory
                      key={category.id}
                      id={category.id}
                      name={category.value}
                      checkedServiceId={checkedServiceId}
                      onChecked={onChecked}
                    />
                  ))
                : phraseServiceList.map(s => (
                    <ServiceCheckbox
                      key={`service-${s.id}`}
                      service={s}
                      checkedId={checkedServiceId}
                      onClick={onChecked}
                    />
                  ))}
            </Box>
            <Box sx={{ position: 'fixed', top: '88vh', width: '100%' }}>
              <Box
                sx={{
                  width: '566px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '56px',
                  mt: '20px',
                  mb: '30px'
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '56px'
                  }}>
                  <ButtonMain
                    textBoxWidth={'71px'}
                    title={'contractor_back'}
                    onClick={onBack}
                    fontSize={'16px'}
                    fontWeight={500}
                    lineHeight={'40px'}
                  />
                </Box>
                <Box
                  sx={{
                    width: '342px'
                  }}>
                  <ButtonDarkBlue title={'mobile_next'} onClick={onNext} disabled={!isCanGoNext()} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </LocalizationContext.Consumer>
  );
};
