import React, { FC, ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { LanguageView } from '../LanguageView';

import { COLOR_GREY_BACKGROUND, COLOR_GREY_TEXT, COLOR_TEXT_MAIN } from '../../../colors';

import { selectProfile } from '../../../app/redux/state';
import { Avatar } from '../../ProfileInfo/components/Avatar';
import { LocalizationContext } from '../../Localization';

interface Props {}
export const UserInfo: FC<Props> = (): ReactElement => {
  const profile = useSelector(selectProfile);

  return (
    <Box
      sx={{
        width: '70%',
        height: '100%',
        p: '24px 20px 0',
        borderRadius: '8px',
        backgroundColor: COLOR_GREY_BACKGROUND
      }}>
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 900,
          letterSpacing: 0.46,
          lineHeight: '31px',
          textAlign: 'end',
          color: COLOR_TEXT_MAIN
        }}>
        {profile.fullName}
      </Typography>
      <Box
        sx={{
          height: '1px',
          backgroundColor: COLOR_GREY_TEXT,
          my: '24px'
        }}
      />
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mb: '24px' }}>
        <Avatar />
      </Box>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '22px',
          color: COLOR_TEXT_MAIN,
          mb: '24px'
        }}>
        {profile.info}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <LocalizationContext.Consumer>
          {({ getWord }) => (
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '22px',
                color: COLOR_TEXT_MAIN,
                mr: '14px'
              }}>
              {getWord('contractor_i_speak')}
            </Typography>
          )}
        </LocalizationContext.Consumer>
        {profile.serviceLanguages.map((lang, index) => (
          <LanguageView key={`LanguageView-${index}`} title={lang} />
        ))}
      </Box>
    </Box>
  );
};
