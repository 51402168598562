import React, { FC } from 'react';
import { Box } from '@mui/material';

import env from '../../environment.json';

interface Props {
  src: string;
}

export const PhotoCardProfile: FC<Props> = ({ src }) => (
  <Box
    component="img"
    alt="photo"
    //@ts-ignore
    src={`${env[env.environment].host}/${src}`}
    sx={{
      width: '95px',
      height: '84px',
      objectFit: 'cover',
      mr: '12px',
      mb: '12px'
    }}
  />
);
