import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { COLOR_TEXT_MAIN } from '../../../../colors';

import { selectProfile } from '../../../../app/redux/state';

import env from '../../../../environment.json';

import { uploadAvatar } from '../../../../app/redux/actions';
import { LocalizationContext } from '../../../../features/Localization';

interface Props {}

export const Avatar: FC<Props> = () => {
  const dispatch = useDispatch();
  const { avatarURL } = useSelector(selectProfile);

  const onImageChange = (event: any) => {
    if (event.target.files != null && event.target.files.length > 0) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        dispatch(uploadAvatar(e.target.result));
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: '16px'
          }}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="raised-button-file"
            onChange={onImageChange}
            type="file"
            name="avatar"
          />
          <label htmlFor="raised-button-file">
            <Box
              component="img"
              sx={{
                height: 56,
                width: 56,
                mr: '12px'
              }}
              alt="avatar"
              //@ts-ignore
              src={avatarURL != null ? `${env[env.environment].host}/${avatarURL}` : `/images/svg/addAvatar.svg`}
            />
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                color: COLOR_TEXT_MAIN
              }}>
              {getWord('contractor_add_profile_picture')}
            </Typography>
          </label>
        </Box>
      )}
    </LocalizationContext.Consumer>
  );
};
