import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Modal } from '../Modal';

import { logout } from '../../app/redux/actions';

interface Props {}

export const LogoutButton: FC<Props> = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleModal = () => setIsModalOpen(!isModalOpen);

  const onLogout = () => {
    dispatch(logout(false));
    navigate('/');
  };

  return (
    <>
      <Box
        onClick={handleModal}
        component="img"
        sx={{
          height: 20,
          width: 20,
          '&:hover': {
            cursor: 'pointer'
          }
        }}
        alt="logout"
        src={`/images/svg/logout.svg`}
      />
      {isModalOpen && (
        <Modal title={'Выйти из аккаунта?'} buttonTitle={'Выйти'} onSubmit={onLogout} onCancel={handleModal} />
      )}
    </>
  );
};
