import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { selectCountryList, selectProfile } from '../../../../app/redux/state';
import { updateProfile } from '../../../../app/redux/actions';
import { LocalizationContext } from '../../../Localization';

interface Props {
  needSaveProfile: boolean;
}

interface State {
  id: number;
}

export const CountryInput: FC<Props> = ({ needSaveProfile }: Props): ReactElement | null => {
  const countries = useSelector(selectCountryList);
  const profile = useSelector(selectProfile);
  const dispatch = useDispatch();

  const [state, setState] = useState<State>({
    id: 0
  });
  //@todo null value
  useEffect(() => setState({ id: profile.country?.id ?? 0 }), [countries, profile.country?.id]);

  const onChange = (event: any) => {
    const id = event.target.value;
    setState({ id });
    if (id !== profile.country?.id) {
      const country = countries.find(obj => obj.id === id);
      if (country != null) {
        dispatch(updateProfile({ ...profile, country: { id: country.id, code: country.code } }, needSaveProfile));
      }
    }
  };

  if (countries.length === 0 || state.id == null) {
    return null;
  }

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <TextField
          id="filled-select"
          select
          value={state.id}
          label={getWord('contractor_location')}
          fullWidth={true}
          onChange={onChange}
          sx={{
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: '#c4c4c4',
                borderWidth: '1px'
              },
              height: '56px',
              mb: '12px'
            },
            height: '56px',
            mb: '16px'
          }}>
          <MenuItem key={`country-0`} sx={{ display: 'flex', alignItems: 'center', p: '5px 10px' }} value={0}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: 0.15
              }}>
              {getWord('contractor_select_country')}
            </Typography>
          </MenuItem>
          {countries.map(country => (
            <MenuItem key={country.id} sx={{ display: 'flex', alignItems: 'center', p: '5px 10px' }} value={country.id}>
              <Box
                component="img"
                src="/images/flag-es.png"
                sx={{
                  width: '15px',
                  mr: '4px'
                }}
              />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: 0.15
                }}>
                {country.name}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      )}
    </LocalizationContext.Consumer>
  );
};
