import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { COLOR_ORANGE, COLOR_WHITE } from '../../../../colors';

import { selectProfile } from '../../../../app/redux/state';

import env from '../../../../environment.json';

interface Props {}

export const Avatar: FC<Props> = () => {
  const { avatarURL, fullName } = useSelector(selectProfile);
  return avatarURL != null ? (
    <Box
      component="img"
      alt="avatar"
      //@ts-ignore
      src={`${env[env.environment].host}/${avatarURL}`}
      sx={{
        borderRadius: '50%',
        width: '160px',
        height: '160px',
        objectFit: 'cover'
      }}
    />
  ) : (
    <Box
      sx={{
        borderRadius: '50%',
        width: '160px',
        height: '160px',
        objectFit: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: COLOR_ORANGE
      }}>
      <Typography
        sx={{
          color: COLOR_WHITE,
          fontSize: '35px'
        }}>
        {/*@todo check this*/}
        {fullName != null && fullName.split(' ')[0] ? fullName.split(' ')[0][0] : 'A'}
      </Typography>
    </Box>
  );
};
