export enum OrderTabType {
  inbox,
  inProgress,
  archive
}

export interface IMessageAdditional {
  version: number;
  userInfo: {
    nickname: string;
    oneSignalID: string;
  };
}

export class MessageAdditional implements IMessageAdditional {
  public version: number;
  public userInfo: {
    nickname: string;
    oneSignalID: string;
    country: string;
  };

  constructor(userInfo: { nickname: string; oneSignalID: string; country: string }) {
    this.version = 2;
    this.userInfo = userInfo;
  }
}

export interface IMessage {
  version: number;
  id: number;
  state: number;
  nickname: string;
  userIdFrom: number | null;
  deviceIdFrom: string;
  userIdTo: number | null;
  deviceIdTo: string;
  topic: string;
  value: string;
  created: string;
  updated: string;
  additional: IMessageAdditional | null;
}

export class Message implements IMessage {
  public version: number;
  public id: number;
  public state: number;
  public nickname: string;
  public userIdFrom: number | null;
  public deviceIdFrom: string;
  public userIdTo: number | null;
  public deviceIdTo: string;
  public topic: string;
  public value: string;
  public created: string;
  public updated: string;
  public additional: IMessageAdditional | null;

  constructor(
    id: number,
    state: number,
    nickname: string,
    userIdFrom: number | null,
    deviceIdFrom: string,
    userIdTo: number | null,
    deviceIdTo: string,
    topic: string,
    value: string,
    created: string,
    updated: string,
    additional: IMessageAdditional | null
  ) {
    this.version = 1;
    this.id = id;
    this.state = state;
    this.nickname = nickname;
    this.userIdFrom = userIdFrom;
    this.deviceIdFrom = deviceIdFrom;
    this.userIdTo = userIdTo;
    this.deviceIdTo = deviceIdTo;
    this.topic = topic;
    this.value = value;
    this.created = created;
    this.updated = updated;
    this.additional = additional;
  }
}

export interface IService {
  id: number;
  country: string;
  categoryID: number;
  category: string;
  name: string;
  manager: string;
  updated: string;
}

export interface ITextElement {
  id: string;
  value: string;
  type: 'normal' | 'bold';
}

export interface IElement {
  id: string;
  type: string;
  title: null | string;
  attention: boolean;
  index: number;
  text: ITextElement[];
  children: IElement[];
}

export interface IPage {
  id: string;
  categoryID: number;
  category: string;
  topic: string;
  icon: string;
  name: string;
  header: string;
  children: IElement[];
}

export class TextElement {
  id: string;
  value: string;
  type: 'normal' | 'bold';

  constructor(id: string, value: string, type: 'normal' | 'bold' = 'normal') {
    this.id = id;
    this.value = value;
    this.type = type;
  }
}

//@todo move to core-server
export interface IExtendedService {
  serviceList: number[];
  description: string;
  imageUrlList: string[];
}

export interface IProfile {
  version: number;
  id: null | number;
  hasGoogleCredentials: boolean;
  avatar: null | string;
  avatarURL: string | null;
  fullName: null | string;
  email: null | string;
  phone: null | string;
  language: null | string;
  serviceLanguages: string[];
  country: null | {
    id: number;
    code: string;
  };
  city: null | {
    id: number | null;
    code: string | null;
    availableNationwide: boolean;
  };
  info: string;
  serviceList: { serviceId: number; categoryName: string; serviceName: string }[];
  approved: boolean;
  review: {
    starCount: number;
    reviewCount: number;
  };
  gallery: string[];
  mode: null | 'client' | 'executor';
  extendedServiceList: IExtendedService[];
}

export interface IOutputChatMessage {
  id: number;
  userId: number;
  userFullName: string;
  approved: number;
  userIdFrom: number;
  userIdTo: number;
  state: number;
  value: string;
  createdAt: string;
}

export interface IService {
  id: number;
  country: string;
  categoryID: number;
  category: string;
  name: string;
  manager: string;
  updated: string;
}
export interface IChatMessage {
  id: number;
  unread: boolean;
  root: boolean;
  topic: string;
  author: string;
  value: string;
  time: string;
  unixTime: number;
  avatarURL: string | null;
}

export interface IOrder {
  id: number;
  state: number;
  userID: number;
  userFullName: string;
  userAvatarURL: string;
  serviceId: number;
  serviceFullName: string;
  lastMessage: IChatMessage;
  unreadMessageIdList: number[];
  data: IChatMessage[];
  review: IReview | null;
}

export interface IOutputOrderMessage {
  id: number;
  orderId: number;
  orderState: number;
  state: number;
  value: string;
  userId: number;
  userFullName: string;
  userAvatarURL: string;
  contactorId: number;
  contractorFullName: string;
  contractorAvatarURL: string;
  senderId: number;
  sendFullName: string;
  serviceId: number;
  serviceFullName: string;
  createdAt: string;
}

export interface IOutputChatMessage {
  id: number;
  userId: number;
  userFullName: string;
  approved: number;
  userIdFrom: number;
  userIdTo: number;
  state: number;
  value: string;
  createdAt: string;
}

export interface IInputChatMessage {
  state: number;
  userIdFrom: number;
  userIdTo: number;
  value: string;
}

export interface IInputOrderMessage {
  state: number;
  orderId: number | null;
  serviceId: number | null;
  userIdFrom: number;
  contractorId: number | null;
  value: string;
}

export interface ICity {
  id: number;
  code: string;
  name: string;
}

export interface ICountry {
  id: number;
  code: string;
  name: string;
  cities: ICity[];
}

export interface IReview {
  id: number;
  state: number;
  orderId: number;
  serviceName: string;
  categoryName: string;
  fullName: string;
  starCount: number;
  review: string;
}

export interface ISuccessAuthorization {
  token: string;
}
