import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';

import { Avatar } from '../../Avatar';

import { COLOR_TEXT_MAIN, COLOR_WHITE } from '../../../colors';
interface Props {}

export class ChatAdminHeader extends PureComponent<Props> {
  render() {
    return (
      <Box
        sx={{
          height: '36px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          m: '36px 24px 27px 25px',
          backgroundColor: COLOR_WHITE
        }}>
        <Box
          sx={{
            height: '36px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}>
          <Avatar avatarUrl="images/avatar.png" fullName="Administrator" />
          <Typography
            sx={{
              color: COLOR_TEXT_MAIN,
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '16px',
              m: '0 19px 0 12px'
            }}>
            Administrator
          </Typography>
        </Box>
      </Box>
    );
  }
}
