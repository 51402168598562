import { AxiosError, AxiosResponse } from 'axios';

import { axiosInstance } from '../../features/Localization/service/api';

import { ICountry, IPage, IProfile, IReview, IService, ISuccessAuthorization } from '../../types';

import { initialProfile } from '../../app/redux/state';

import env from '../../environment.json';

const cOauthURL = '/v2/authorization/user/desktop/google';
export const getOauthToken = async (
  idToken: string | null,
  serverAuthCode: string | null,
  language: string
): Promise<{
  token: string;
} | null> => {
  let result: {
    token: string;
  } | null = null;
  try {
    const response: AxiosResponse<{
      token: string;
    }> = await axiosInstance.post(
      // @ts-ignore
      `${env[env.environment].host}${cOauthURL}`,
      {
        type: 'executor',
        idToken,
        serverAuthCode,
        language
      }
    );
    result = response.data;
  } catch (error) {
    console.log(error);
  }
  return result;
};

const cBasicRegistrationURL = '/v2/authorization/user/basic/registration';
export const getBasicRegistrationToken = async (
  login: string,
  password: string,
  language: string
): Promise<ISuccessAuthorization | AxiosError | null> => {
  let result: ISuccessAuthorization | AxiosError | null = null;
  try {
    const response: AxiosResponse<{
      token: string;
    }> = await axiosInstance.post(
      // @ts-ignore
      `${env[env.environment].host}${cBasicRegistrationURL}`,
      {
        type: 'executor',
        login,
        password,
        language,
        oneSignalId: null
      }
    );
    result = response.data;
  } catch (error: unknown) {
    console.log(error);
    if (error instanceof AxiosError) {
      result = error;
    } else {
      result = null;
    }
  }
  return result;
};

const cBasicLoginURL = '/v2/authorization/user/basic/login';
export const getBasicLoginToken = async (
  login: string,
  password: string,
  language: string
): Promise<ISuccessAuthorization | AxiosError | null> => {
  let result: ISuccessAuthorization | AxiosError | null = null;
  try {
    const response: AxiosResponse<{
      token: string;
    }> = await axiosInstance.post(
      // @ts-ignore
      `${env[env.environment].host}${cBasicLoginURL}`,
      {
        type: 'executor',
        login,
        password,
        language,
        oneSignalId: null
      }
    );
    result = response.data;
  } catch (error: unknown) {
    console.log(error);
    if (error instanceof AxiosError) {
      result = error;
    } else {
      result = null;
    }
  }
  return result;
};

const cDeleteAccountURL = '/v1/user';
export const deleteAccount = async (): Promise<boolean | Error> => {
  let result: boolean | Error = true;
  try {
    // @ts-ignore
    await axiosInstance.create().delete(`${env[env.environment].socket}${cDeleteAccountURL}`);
  } catch (error: any) {
    result = error;
  }
  return result;
};

const cCategoryListURL = '/v1/service/list/category';
export const getCategoryList = async (language: string): Promise<{ id: number; country: string; value: string }[]> => {
  let result: { id: number; country: string; value: string }[] = [];
  try {
    const response: AxiosResponse<{ id: number; country: string; value: string }[]> = await axiosInstance.get(
      // @ts-ignore
      `${env[env.environment].host}${cCategoryListURL}?language=${language}`
    );
    result = [...response.data];
  } catch (error) {
    console.log(error);
  }
  return result;
};

const cServiceListURL = '/v1/service/list/short';
export const getServiceList = async (language: string): Promise<IService[]> => {
  let result: IService[] = [];
  try {
    const response: AxiosResponse<IService[]> = await axiosInstance.get(
      // @ts-ignore
      `${env[env.environment].host}${cServiceListURL}?country=spain&language=${language}`
    );
    result = [...response.data];
  } catch (error) {
    console.log(error);
  }
  return result;
};

const cServiceURL = '/v1/service';
export const getService = async (id: number, language: string): Promise<IPage | null> => {
  let result: IPage | null = null;
  try {
    const response: AxiosResponse<IPage> = await axiosInstance.get(
      // @ts-ignore
      `${env[env.environment].host}${cServiceURL}?id=${id}&language=${language}`
    );
    result = response.data;
  } catch (error) {
    console.log(error);
  }
  return result;
};

const cProfileURL = '/v1/authorization/user/profile';
export const getProfile = async (token: string): Promise<IProfile | AxiosError | Error> => {
  let result: IProfile | AxiosError | Error = initialProfile;
  try {
    axiosInstance.defaults.headers.common.Authorization = `token=${token}`;
    const response = await axiosInstance.get(
      // @ts-ignore
      `${env[env.environment].host}${cProfileURL}`
    );
    result = response.data;
  } catch (error: any) {
    console.log(error);
    result = error;
  }
  return result;
};
export const putProfile = async (token: string, value: IProfile): Promise<boolean | Error> => {
  let result: boolean | Error = true;
  try {
    axiosInstance.defaults.headers.common.Authorization = `token=${token}`;
    const response: AxiosResponse<boolean> = await axiosInstance.put(
      // @ts-ignore
      `${env[env.environment].host}${cProfileURL}`,
      value
    );
    result = response.data;
  } catch (error) {
    console.log(error);
  }
  return result;
};

const cProfileImageURL = '/v1/authorization/user/profile/image/base64';
export const postImage = async (token: string, data: string[]): Promise<string[] | AxiosError | Error> => {
  let result: string[] | AxiosError | Error = [];
  try {
    axiosInstance.defaults.headers.common.Authorization = `token=${token}`;
    const response = await axiosInstance.post(
      // @ts-ignore
      `${env[env.environment].host}${cProfileImageURL}`,
      { data }
    );
    result = response.data.data;
  } catch (error: any) {
    console.log(error);
    result = error;
  }
  return result;
};

const cCityListURL = '/v1/authorization/cities';
export const getCountryList = async (language: string): Promise<ICountry[]> => {
  let result: ICountry[] = [];
  try {
    const response: AxiosResponse<ICountry[]> = await axiosInstance.get(
      // @ts-ignore
      `${env[env.environment].host}${cCityListURL}?language=${language}`
    );
    result = [...response.data];
  } catch (error) {
    console.log(error);
  }
  return result;
};

const cReviewURL = '/v1/authorization/user/review';
export const getReviewList = async (token: string): Promise<IReview[] | AxiosError | Error> => {
  let result: IReview[] | AxiosError | Error = [];
  try {
    axiosInstance.defaults.headers.common.Authorization = `token=${token}`;
    const response = await axiosInstance.get(
      // @ts-ignore
      `${env[env.environment].host}${cReviewURL}`
    );
    result = response.data;
  } catch (error: any) {
    console.log(error);
    result = error;
  }
  return result;
};

const cServiceListSearchURL = '/v1/service/list/search';
export const getPhraseServiceList = async (phrase: string, language: string): Promise<IService[]> => {
  let result: IService[] = [];
  try {
    const response: AxiosResponse<IService[]> = await axiosInstance.get(
      // @ts-ignore
      `${env[env.environment].host}${cServiceListSearchURL}?phrase=${phrase}&language=${language}`
    );
    result = [...response.data];
  } catch (error) {
    console.log(error);
  }
  return result;
};
