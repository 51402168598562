import React, { FC, ReactElement, useState } from 'react';

import { OrderListManager } from '../OrderListManager';
import { Chat } from '../Chat';
import { OrderTabType } from '../../types';
import { Box } from '@mui/material';

interface Props {}
interface State {
  activeId: OrderTabType;
  activeIndex: number;
}
export const ChatManager: FC<Props> = (): ReactElement => {
  const [state, setState] = useState<State>({ activeId: OrderTabType.inbox, activeIndex: -1 });

  const onChange = (id: OrderTabType, index: number) => setState({ activeId: id, activeIndex: index });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <OrderListManager onClick={onChange} />
      <Chat activeId={state.activeId} activeIndex={state.activeIndex} />
    </Box>
  );
};
