import { Box } from '@mui/material';
import React, { FC, ReactElement } from 'react';

import { LogoutButton } from '../../../LogoutButton';
import { ChooseAppLanguageSidebar } from '../../../ChooseAppLanguageSidebar';

interface Props {}

export const ServiceButtons: FC<Props> = (): ReactElement => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <Box
        sx={{
          mt: '29px',
          mb: '29px',
          position: 'relative'
        }}>
        <ChooseAppLanguageSidebar />
      </Box>
      <LogoutButton />
    </Box>
  );
};
