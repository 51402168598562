import React, { FC, ReactElement } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { has } from 'lodash';

import { ButtonMain } from '../../../features/ButtonMain';
import { ButtonDarkBlue } from '../../../features/ButtonDarkBlue';
// import { SelectLanguages } from '../../../features/SelectLanguages';
import { DescriptionInput } from '../../../features/ProfileInfo/components/DescriptionInput';
import { Avatar } from '../components/Avatar';

import { selectProfile } from '../../../app/redux/state';
import { updateProfile } from '../../../app/redux/actions';
import { LogoMain } from '../../../features/LogoMain';
import { COLOR_TEXT_MAIN } from '../../../colors';
import { LocalizationContext } from '../../../features/Localization';
import { CustomSelectLanguages } from '../../../features/CustomSelectLanguages';

interface Props {}

export const SignUp3: FC<Props> = (): ReactElement => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const profile = useSelector(selectProfile);

  const onNext = () => {
    const previousExtendedServiceList = has(profile, 'extendedServiceList') ? [...profile.extendedServiceList] : [];
    dispatch(
      updateProfile(
        {
          ...profile,
          extendedServiceList: [...previousExtendedServiceList, ...location.state.serviceList]
        },
        true
      )
    );
    navigate('/');
  };

  const onBack = () => navigate('/signUp/2', { state: { ...location.state } });

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', ml: '35px', mt: '30px' }}>
            <LogoMain />
          </Box>
          <Box
            sx={{
              width: '566px'
            }}>
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '26px',
                color: COLOR_TEXT_MAIN,
                mb: '53px'
              }}>
              {getWord('contractor_signup')}
            </Typography>
            <Avatar />
            <CustomSelectLanguages needSaveProfile={true} />
            <Box sx={{ mt: '16px', mb: '36px' }}>
              <DescriptionInput needSaveProfile={false} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '56px',
                mt: '12px'
              }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <ButtonMain
                  textBoxWidth={'71px'}
                  title={'contractor_back'}
                  onClick={onBack}
                  fontSize={'16px'}
                  fontWeight={500}
                  lineHeight={'40px'}
                />
              </Box>
              <Box
                sx={{
                  width: '342px'
                }}>
                <ButtonDarkBlue title={'contractor_finish'} onClick={onNext} disabled={false} />
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </LocalizationContext.Consumer>
  );
};
