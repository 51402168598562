import React, { FC, ReactElement } from 'react';
import { Box } from '@mui/material';

import { ButtonMain } from '../../../../ButtonMain';

interface Props {
  onAccept: () => void;
}

export const OrderAcceptDecline: FC<Props> = ({ onAccept }: Props): ReactElement => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end'
    }}>
    {/*<Box*/}
    {/*  sx={{*/}
    {/*    display: 'flex',*/}
    {/*    flexDirection: 'row',*/}
    {/*    alignItems: 'center'*/}
    {/*  }}*/}
    {/*  onClick={() => {}}>*/}
    {/*  <Box*/}
    {/*    component="img"*/}
    {/*    sx={{*/}
    {/*      height: 14,*/}
    {/*      width: 14,*/}
    {/*      mr: '6px'*/}
    {/*    }}*/}
    {/*    alt="avatar"*/}
    {/*    src={'/images/svg/decline.svg'}*/}
    {/*  />*/}
    {/*  <Typography*/}
    {/*    sx={{*/}
    {/*      fontSize: '14px',*/}
    {/*      fontStyle: 'normal',*/}
    {/*      fontWeight: 600,*/}
    {/*      lineHeight: '14px',*/}
    {/*      color: COLOR_RED,*/}
    {/*      mr: '21px'*/}
    {/*    }}>*/}
    {/*    Decline*/}
    {/*  </Typography>*/}
    {/*</Box>*/}
    <ButtonMain
      textBoxWidth={'85px'}
      title={'Accept Task'}
      onClick={onAccept}
      fontSize={'14px'}
      fontWeight={600}
      lineHeight={'20px'}
    />
  </Box>
);
