import { createTheme } from '@mui/material/styles';
import { COLOR_TEXT_MAIN } from '../../colors';

export const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        standard: {
          borderBottomColor: 'red',
          '&:focus': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          // backgroundColor: COLOR_WHITE,
          // borderRadius: '8px',
          color: COLOR_TEXT_MAIN,
          fontSize: '16px',
          fontWeight: 400,
          letterSpacing: 0.15
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          color: COLOR_TEXT_MAIN,
          fontSize: '14px',
          fontWeight: 400,
          letterSpacing: 0.15,
          display: 'flex',
          alignItems: 'center'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#00000099',
            fontSize: '10px',
            fontWeight: 400
          },
          '&.MuiInputLabel-outlined': {
            color: '#00000099',
            fontSize: '10px'
          },
          '&.MuiInputLabel-standard': {
            color: '#00000099',
            fontSize: '10px'
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Raleway'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: '#FFFFFF',
          width: '343px',
          height: '48px',
          borderRadius: '16px',
          fontSize: '17px',
          lineHeight: '24px',
          fontWeight: '400',
          textTransform: 'none',
          ':hover': {
            backgroundColor: '#FFFFFF'
          }
        },
        text: {
          fontSize: '13px',
          lineHeight: '24px',
          fontWeight: '400',
          textTransform: 'none',
          ':hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    }
  }
});
