import { PageContainer } from '../../features/PageContainer';
import { SideBar } from '../../features/SideBar';
import { ChatManager } from '../../features/ChatManager';

export const Main = () => (
  <PageContainer>
    <SideBar />
    <ChatManager />
  </PageContainer>
);
