import React, { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { has } from 'lodash';

import { Header } from '../../Header';
import { selectProfile } from '../../../app/redux/state';
import { IExtendedService } from '../../../types';
import { ServiceViewProfile } from '../../ServiceViewProfile';

interface Props {}
export const ServiceInfo: FC<Props> = (): ReactElement => {
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);
  const onGoBack = () => navigate('/profile');

  const onEditService = (index: number) => navigate(`/edit/${index}`);

  const serviceList: IExtendedService[] = has(profile, 'extendedServiceList') ? profile.extendedServiceList : [];

  return (
    <Box
      sx={{
        width: '100%',
        pt: '5px'
      }}>
      <Header title={'mobile_services'} onClick={() => console.log()} justifyContent={true}>
        <Box
          onClick={onGoBack}
          component="img"
          alt="avatar"
          src={'/images/svg/closeOverlay.svg'}
          sx={{
            width: '16px',
            height: '16px',
            objectFit: 'cover',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
        />
      </Header>
      <Box
        sx={{
          pt: '2px',
          height: 'calc(100% - 87px)'
        }}>
        {serviceList.map((service, index) => (
          <ServiceViewProfile
            key={`ServiceViewProfile-${index}`}
            isMorePhoto={false}
            canEdit={false}
            onEditService={() => onEditService(index)}
            service={service}
          />
        ))}
      </Box>
    </Box>
  );
};
