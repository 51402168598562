import { IOrder, IPage, IProfile, IService, IChatMessage, OrderTabType, ICountry } from '../../types';

export const APP_UPDATE_TOPIC_LIST = 'app/UPDATE_TOPIC_LIST';
export const APP_AUTHORIZATION = 'app/APP_AUTHORIZATION';
export const APP_UPDATE_USER_ID = 'app/UPDATE_USER_ID';
export const APP_REFRESH_CATEGORY_LIST = 'app/REFRESH_CATEGORY_LIST';
export const APP_UPDATE_CATEGORY_LIST = 'app/UPDATE_CATEGORY_LIST';
export const APP_UPDATE_SERVICE_LIST = 'app/APP_UPDATE_SERVICE_LIST';
export const APP_REFRESH_SERVICE = 'app/REFRESH_SERVICE';
export const APP_UPDATE_SERVICE = 'app/UPDATE_SERVICE';
export const APP_UPDATE_TOKEN = 'app/UPDATE_TOKEN';
export const APP_UPDATE_PAYLOAD = 'app/UPDATE_PAYLOAD';
export const APP_MARK_READ_TOPIC = 'app/MARK_READ_TOPIC';
export const APP_SEND_MESSAGE = 'app/SEND_MESSAGE';

export const APP_UPDATE_PROFILE = 'app/UPDATE_PROFILE';
export const APP_UPDATE_UNREAD_CHAT_MESSAGE_COUNT = 'app/UPDATE_UNREAD_CHAT_MESSAGE_COUNT';
export const APP_UPDATE_UNREAD_INBOX_ORDER_MESSAGE_COUNT = 'app/UPDATE_UNREAD_INBOX_ORDER_MESSAGE_COUNT';
export const APP_UPDATE_UNREAD_IN_PROGRESS_ORDER_MESSAGE_COUNT = 'app/UPDATE_UNREAD_IN_PROGRESS_ORDER_MESSAGE_COUNT';
export const APP_UPDATE_UNREAD_ARCHIVE_ORDER_MESSAGE_COUNT = 'app/UPDATE_UNREAD_ARCHIVE_ORDER_MESSAGE_COUNT';
export const APP_UPDATE_ORDERS = 'app/UPDATE_ORDERS';
export const APP_UPDATE_LAST_CHAT_MESSAGE = 'app/UPDATE_LAST_CHAT_MESSAGE';
export const APP_UPDATE_CHAT_MESSAGE_LIST = 'app/UPDATE_CHAT_MESSAGE_LIST';
export const APP_SEND_CHAT_MESSAGE = 'app/SEND_CHAT_MESSAGE';
export const APP_SEND_ORDER_MESSAGE = 'app/SEND_ORDER_MESSAGE';
export const APP_READ_ORDER_MESSAGE = 'app/READ_ORDER_MESSAGE';
export const APP_READ_CHAT_MESSAGE = 'app/READ_CHAT_MESSAGE';
export const APP_UPLOAD_AVATAR = 'app/UPLOAD_AVATAR';
export const APP_LOGOUT = 'app/LOGOUT';
export const APP_UPDATE_COUNTRY_LIST = 'app/UPDATE_COUNTRY_LIST';
export const APP_UPLOAD_IMAGE = 'app/UPLOAD_IMAGE';
export const APP_CHANGE_LANGUAGE = 'app/CHANGE_LANGUAGE';
export const APP_REFRESH_COUNTRY_LIST = 'app/REFRESH_COUNTRY_LIST';

export const APP_BASIC_REGISTRATION = 'app/BASIC_REGISTRATION';
export const APP_BASIC_LOGIN = 'app/BASIC_LOGIN';

export const APP_UPDATE_PHRASE = 'app/UPDATE_PHRASE';
export const APP_UPDATE_PHRASE_SERVICE_LIST = 'app/UPDATE_PHRASE_SERVICE_LIST';

export const APP_ACCEPT_ORDER = 'app/ACCEPT_ORDER';
export const APP_COMPLETE_ORDER = 'app/COMPLETE_ORDER';

export interface IAppAuthorizationAction {
  type: typeof APP_AUTHORIZATION;
  payload: {
    idToken: string | null;
    serverAuthCode: string | null;
    language: string;
  };
}

export interface IAppUpdateUserIdAction {
  type: typeof APP_UPDATE_USER_ID;
  payload: {
    value: number | null;
  };
}

export interface IAppRefreshCategoryListAction {
  type: typeof APP_REFRESH_CATEGORY_LIST;
  payload: {
    language: string;
  };
}

export interface IAppUpdateCategoryListAction {
  type: typeof APP_UPDATE_CATEGORY_LIST;
  payload: {
    data: { id: number; value: string }[];
  };
}

export interface IAppUpdateServiceListAction {
  type: typeof APP_UPDATE_SERVICE_LIST;
  payload: {
    data: IService[];
  };
}

export interface IAppRefreshServiceAction {
  type: typeof APP_REFRESH_SERVICE;
  payload: {
    value: number;
    language: string;
  };
}

export interface IAppUpdateServiceAction {
  type: typeof APP_UPDATE_SERVICE;
  payload: {
    value: IPage | null;
  };
}

export interface IAppUpdateTokenAction {
  type: typeof APP_UPDATE_TOKEN;
  payload: {
    value: string | null;
  };
}

export interface IAppUpdatePayloadAction {
  type: typeof APP_UPDATE_PAYLOAD;
  payload: {
    value: { name: string | null; surname: string | null; avatar: string | null };
  };
}

export interface IAppMarkReadTopicAction {
  type: typeof APP_MARK_READ_TOPIC;
  payload: {
    value: string;
  };
}

export interface IAppSendMessageAction {
  type: typeof APP_SEND_MESSAGE;
  payload: {
    topic: string;
    text: string;
  };
}

export interface IAppUpdateProfileAction {
  type: typeof APP_UPDATE_PROFILE;
  payload: { value: IProfile; needSave: boolean };
}

export interface IAppUpdateUnreadChatMessageCountAction {
  type: typeof APP_UPDATE_UNREAD_CHAT_MESSAGE_COUNT;
  payload: {
    value: number;
  };
}

export interface IAppUpdateUnreadInboxOrderMessageCountAction {
  type: typeof APP_UPDATE_UNREAD_INBOX_ORDER_MESSAGE_COUNT;
  payload: {
    value: number;
  };
}

export interface IAppUpdateUnreadInProgressOrderMessageCountAction {
  type: typeof APP_UPDATE_UNREAD_IN_PROGRESS_ORDER_MESSAGE_COUNT;
  payload: {
    value: number;
  };
}

export interface IAppUpdateUnreadArchiveOrderMessageCountAction {
  type: typeof APP_UPDATE_UNREAD_ARCHIVE_ORDER_MESSAGE_COUNT;
  payload: {
    value: number;
  };
}

export interface IAppUpdateOrdersAction {
  type: typeof APP_UPDATE_ORDERS;
  payload: {
    data: Record<OrderTabType, IOrder[]>;
  };
}

export interface IAppUpdateLastChatMessageAction {
  type: typeof APP_UPDATE_LAST_CHAT_MESSAGE;
  payload: {
    value: IChatMessage | null;
  };
}

export interface IAppUpdateChatMessageListAction {
  type: typeof APP_UPDATE_CHAT_MESSAGE_LIST;
  payload: {
    data: IChatMessage[];
  };
}

export interface IAppSendChatMessageAction {
  type: typeof APP_SEND_CHAT_MESSAGE;
  payload: {
    value: string;
    userIdFrom: number;
    userIdTo: number;
  };
}

export interface IAppSendOrderMessageAction {
  type: typeof APP_SEND_ORDER_MESSAGE;
  payload: {
    value: string;
    userId: number;
    orderId: number;
    serviceId: number;
  };
}

export interface IAppReadOrderMessageAction {
  type: typeof APP_READ_ORDER_MESSAGE;
  payload: {
    idList: number[];
  };
}

export interface IAppReadChatMessageAction {
  type: typeof APP_READ_CHAT_MESSAGE;
}

export interface IAppUploadAvatarAction {
  type: typeof APP_UPLOAD_AVATAR;
  payload: {
    avatar: string;
  };
}

export interface IAppLogoutAction {
  type: typeof APP_LOGOUT;
  payload: { deleteAccount: boolean };
}

export interface IAppUpdateCountryListAction {
  type: typeof APP_UPDATE_COUNTRY_LIST;
  payload: {
    data: ICountry[];
  };
}

export interface IAppUploadImageAction {
  type: typeof APP_UPLOAD_IMAGE;
  payload: {
    value: string;
  };
}

export interface IAppChangeLanguageAction {
  type: typeof APP_CHANGE_LANGUAGE;
  payload: {
    value: string;
  };
}

export interface IAppRefreshCountryListAction {
  type: typeof APP_REFRESH_COUNTRY_LIST;
  payload: {
    language: string;
  };
}

export interface IAppBasicRegistrationAction {
  type: typeof APP_BASIC_REGISTRATION;
  payload: {
    login: string;
    password: string;
    language: string;
  };
}

export interface IAppBasicLoginAction {
  type: typeof APP_BASIC_LOGIN;
  payload: {
    login: string;
    password: string;
    language: string;
  };
}

export interface IAppUpdatePhraseAction {
  type: typeof APP_UPDATE_PHRASE;
  payload: {
    value: string | null;
    language: string;
  };
}

export interface IAppUpdatePhraseServiceListAction {
  type: typeof APP_UPDATE_PHRASE_SERVICE_LIST;
  payload: {
    data: IService[];
  };
}

export interface IAppAcceptOrderAction {
  type: typeof APP_ACCEPT_ORDER;
  payload: {
    value: number;
  };
}

export interface IAppCompleteOrderAction {
  type: typeof APP_COMPLETE_ORDER;
  payload: {
    value: number;
  };
}

export type IAppActionTypes =
  | IAppAuthorizationAction
  | IAppUpdateUserIdAction
  | IAppRefreshCategoryListAction
  | IAppUpdateCategoryListAction
  | IAppUpdateServiceListAction
  | IAppRefreshServiceAction
  | IAppUpdateServiceAction
  | IAppUpdateTokenAction
  | IAppUpdatePayloadAction
  | IAppMarkReadTopicAction
  | IAppSendMessageAction
  | IAppUpdateProfileAction
  | IAppUpdateUnreadChatMessageCountAction
  | IAppUpdateUnreadInboxOrderMessageCountAction
  | IAppUpdateUnreadInProgressOrderMessageCountAction
  | IAppUpdateUnreadArchiveOrderMessageCountAction
  | IAppUpdateOrdersAction
  | IAppUpdateLastChatMessageAction
  | IAppUpdateChatMessageListAction
  | IAppSendChatMessageAction
  | IAppSendOrderMessageAction
  | IAppReadChatMessageAction
  | IAppUploadAvatarAction
  | IAppLogoutAction
  | IAppUpdateCountryListAction
  | IAppUploadImageAction
  | IAppChangeLanguageAction
  | IAppRefreshCountryListAction
  | IAppBasicRegistrationAction
  | IAppBasicLoginAction
  | IAppUpdatePhraseAction
  | IAppUpdatePhraseServiceListAction
  | IAppAcceptOrderAction
  | IAppCompleteOrderAction;

export const authorization = (
  idToken: string | null,
  serverAuthCode: string | null,
  language: string
): IAppAuthorizationAction => ({
  type: APP_AUTHORIZATION,
  payload: {
    idToken,
    serverAuthCode,
    language
  }
});

export const updateUserID = (value: number | null): IAppUpdateUserIdAction => ({
  type: APP_UPDATE_USER_ID,
  payload: { value }
});

export const refreshCategoryList = (language: string): IAppRefreshCategoryListAction => ({
  type: APP_REFRESH_CATEGORY_LIST,
  payload: { language }
});

export const updateCategoryList = (data: { id: number; value: string }[]): IAppUpdateCategoryListAction => ({
  type: APP_UPDATE_CATEGORY_LIST,
  payload: { data }
});

export const updateServiceList = (data: IService[]): IAppUpdateServiceListAction => ({
  type: APP_UPDATE_SERVICE_LIST,
  payload: { data }
});

export const refreshService = (value: number, language: string): IAppRefreshServiceAction => ({
  type: APP_REFRESH_SERVICE,
  payload: { value, language }
});

export const updateService = (value: IPage | null): IAppUpdateServiceAction => ({
  type: APP_UPDATE_SERVICE,
  payload: { value }
});

export const updateToken = (value: string | null): IAppUpdateTokenAction => ({
  type: APP_UPDATE_TOKEN,
  payload: { value }
});

export const updatePayload = (value: {
  name: string | null;
  surname: string | null;
  avatar: string | null;
}): IAppUpdatePayloadAction => ({
  type: APP_UPDATE_PAYLOAD,
  payload: { value }
});

export const markReadTopic = (value: string): IAppMarkReadTopicAction => ({
  type: APP_MARK_READ_TOPIC,
  payload: { value }
});

export const sendMessage = (topic: string, text: string): IAppSendMessageAction => ({
  type: APP_SEND_MESSAGE,
  payload: { topic, text }
});

export const updateProfile = (value: IProfile, needSave: boolean = false): IAppUpdateProfileAction => ({
  type: APP_UPDATE_PROFILE,
  payload: { value, needSave }
});

export const updateUnreadChatMessageCount = (value: number): IAppUpdateUnreadChatMessageCountAction => ({
  type: APP_UPDATE_UNREAD_CHAT_MESSAGE_COUNT,
  payload: { value }
});

export const updateUnreadInboxOrderMessageCount = (value: number): IAppUpdateUnreadInboxOrderMessageCountAction => ({
  type: APP_UPDATE_UNREAD_INBOX_ORDER_MESSAGE_COUNT,
  payload: { value }
});

export const updateUnreadInProgressOrderMessageCount = (
  value: number
): IAppUpdateUnreadInProgressOrderMessageCountAction => ({
  type: APP_UPDATE_UNREAD_IN_PROGRESS_ORDER_MESSAGE_COUNT,
  payload: { value }
});

export const updateUnreadArchiveOrderMessageCount = (
  value: number
): IAppUpdateUnreadArchiveOrderMessageCountAction => ({
  type: APP_UPDATE_UNREAD_ARCHIVE_ORDER_MESSAGE_COUNT,
  payload: { value }
});

export const updateOrders = (data: Record<OrderTabType, IOrder[]>): IAppUpdateOrdersAction => ({
  type: APP_UPDATE_ORDERS,
  payload: { data }
});

export const updateLastChatMessage = (value: IChatMessage | null): IAppUpdateLastChatMessageAction => ({
  type: APP_UPDATE_LAST_CHAT_MESSAGE,
  payload: { value }
});

export const updateChatMessageList = (data: IChatMessage[]): IAppUpdateChatMessageListAction => ({
  type: APP_UPDATE_CHAT_MESSAGE_LIST,
  payload: { data }
});

export const sendChatMessage = (value: string, userIdFrom: number, userIdTo: number): IAppSendChatMessageAction => ({
  type: APP_SEND_CHAT_MESSAGE,
  payload: { value, userIdFrom, userIdTo }
});

export const sendOrderMessage = (
  value: string,
  userId: number,
  orderId: number,
  serviceId: number
): IAppSendOrderMessageAction => ({
  type: APP_SEND_ORDER_MESSAGE,
  payload: { value, userId, orderId, serviceId }
});

export const readOrderMessage = (idList: number[]): IAppReadOrderMessageAction => ({
  type: APP_READ_ORDER_MESSAGE,
  payload: { idList }
});

export const readChatMessage = (): IAppReadChatMessageAction => ({
  type: APP_READ_CHAT_MESSAGE
});

export const uploadAvatar = (avatar: string): IAppUploadAvatarAction => ({
  type: APP_UPLOAD_AVATAR,
  payload: {
    avatar
  }
});

export const logout = (deleteAccount: boolean): IAppLogoutAction => ({
  type: APP_LOGOUT,
  payload: { deleteAccount }
});

export const updateCountryList = (data: ICountry[]): IAppUpdateCountryListAction => ({
  type: APP_UPDATE_COUNTRY_LIST,
  payload: {
    data
  }
});

export const uploadImage = (value: string): IAppUploadImageAction => ({
  type: APP_UPLOAD_IMAGE,
  payload: {
    value
  }
});

export const changeLanguage = (value: string): IAppChangeLanguageAction => ({
  type: APP_CHANGE_LANGUAGE,
  payload: {
    value
  }
});

export const refreshCountryList = (language: string): IAppRefreshCountryListAction => ({
  type: APP_REFRESH_COUNTRY_LIST,
  payload: { language }
});

export const basicRegistration = (login: string, password: string, language: string): IAppBasicRegistrationAction => ({
  type: APP_BASIC_REGISTRATION,
  payload: { login, password, language }
});

export const basicLogin = (login: string, password: string, language: string): IAppBasicLoginAction => ({
  type: APP_BASIC_LOGIN,
  payload: { login, password, language }
});

export const updatePhrase = (value: string | null, language: string): IAppUpdatePhraseAction => ({
  type: APP_UPDATE_PHRASE,
  payload: { value, language }
});

export const updatePhraseServiceList = (data: IService[]): IAppUpdatePhraseServiceListAction => ({
  type: APP_UPDATE_PHRASE_SERVICE_LIST,
  payload: { data }
});

export const acceptOrder = (value: number): IAppAcceptOrderAction => ({
  type: APP_ACCEPT_ORDER,
  payload: { value }
});

export const completeOrder = (value: number): IAppCompleteOrderAction => ({
  type: APP_COMPLETE_ORDER,
  payload: { value }
});
