import React, { FC, ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { COLOR_TEXT_MAIN } from '../../../../colors';
import { LocalizationContext } from '../../../Localization';

interface Props {}

export const ProfilePreviewButton: FC<Props> = (): ReactElement => {
  const navigate = useNavigate();
  const onOpenPreview = () => navigate('/preview');
  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Box
          onClick={onOpenPreview}
          sx={{
            p: '7px 21px',
            height: '20px',
            border: '1px solid #143248',
            borderRadius: 70,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              color: COLOR_TEXT_MAIN
            }}>
            {getWord('contractor_profile_preview')}
          </Typography>
        </Box>
      )}
    </LocalizationContext.Consumer>
  );
};
