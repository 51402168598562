import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { has } from 'lodash';

import { ButtonMain } from '../../../features/ButtonMain';
import { ButtonDarkBlue } from '../../../features/ButtonDarkBlue';
import { AddService } from '../components/AddService';

import { COLOR_GREY_TEXT, COLOR_TEXT_MAIN } from '../../../colors';

import { IExtendedService } from '../../../types';
import { LocalizationContext } from '../../../features/Localization';
import { LogoMain } from '../../../features/LogoMain';

interface Props {}
interface State {
  serviceList: IExtendedService[];
}

export const SignUp2: FC<Props> = (): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const onBack = () => navigate('/signUp/1');

  const initState = () => {
    const serviceList: IExtendedService[] = [];
    if (has(location.state, 'checkedServiceId')) {
      location.state.checkedServiceId.forEach((id: string) => {
        serviceList.push({
          serviceList: [parseInt(id, 10)],
          description: '',
          imageUrlList: []
        });
      });
    }
    if (has(location.state, 'serviceList')) {
      serviceList.push(...location.state.serviceList);
    }
    return serviceList;
  };

  const [state, setState] = useState<State>({ serviceList: [...initState()] });

  const onNext = () => navigate('/signUp/3', { state: { ...state } });

  const isCanGoNext = () => true;

  const onChangeDescription = (index: number, value: string) =>
    setState({
      serviceList: [
        ...state.serviceList.slice(0, index),
        { ...state.serviceList[index], description: value },
        ...state.serviceList.slice(index + 1)
      ]
    });

  const onUpdatePhotoList = (index: number, data: string[]) =>
    setState({
      serviceList: [
        ...state.serviceList.slice(0, index),
        { ...state.serviceList[index], imageUrlList: [...data] },
        ...state.serviceList.slice(index + 1)
      ]
    });

  const onRemove = (index: number) =>
    setState({
      serviceList: [...state.serviceList.slice(0, index), ...state.serviceList.slice(index + 1)]
    });

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', ml: '35px', mt: '30px' }}>
            <LogoMain />
          </Box>
          <Box
            sx={{
              width: '566px'
            }}>
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '26px',
                color: COLOR_TEXT_MAIN,
                mb: '53px'
              }}>
              {getWord('contractor_signup')}
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 400,
                letterSpacing: 0.46,
                color: COLOR_TEXT_MAIN,
                mt: '53px',
                mb: '21px'
              }}>
              {getWord('contractor_add_photos_and_descriptions')}
            </Typography>

            <Box sx={{ height: '1px', backgroundColor: COLOR_GREY_TEXT, mb: '21px' }} />
            <Box>
              {state.serviceList.map((s, index) => (
                <AddService
                  index={index}
                  key={`addService-${index}`}
                  service={s}
                  onUpdateDescription={onChangeDescription}
                  onUpdatePhotoList={onUpdatePhotoList}
                  onRemove={onRemove}
                />
              ))}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '56px',
                mt: '12px',
                mb: '30px'
              }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <ButtonMain
                  textBoxWidth={'71px'}
                  title={'contractor_back'}
                  onClick={onBack}
                  fontSize={'16px'}
                  fontWeight={500}
                  lineHeight={'40px'}
                />
              </Box>
              <Box
                sx={{
                  width: '342px'
                }}>
                <ButtonDarkBlue title={'mobile_next'} onClick={onNext} disabled={!isCanGoNext()} />
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </LocalizationContext.Consumer>
  );
};
