import React, { FC, ReactElement, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonMain } from '../../ButtonMain';
import { COLOR_TEXT_MAIN } from '../../../colors';
import { sendChatMessage, sendOrderMessage } from '../../../app/redux/actions';
import { selectProfile } from '../../../app/redux/state';
import { IOrder } from '../../../types';
import { LocalizationContext } from '../../Localization';

interface Props {
  order: IOrder | null;
}
interface State {
  message: string;
}

export const MessageInput: FC<Props> = ({ order }): ReactElement => {
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);
  const [state, setState] = useState<State>({ message: '' });

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setState({ message: event.target.value });

  const onSend = () => {
    if (order != null) {
      dispatch(sendOrderMessage(state.message, order.userID, order.id, order.serviceId));
    } else {
      dispatch(sendChatMessage(state.message, profile.id ?? 1, -1));
    }
    setState({ message: '' });
  };

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: '0 12px 0 21px',
            border: '1px solid #BCC7D0',
            borderRadius: '30px',
            m: '0 26px 56px 24px'
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
            <Box
              component="img"
              alt="clip"
              src={'/images/svg/clip.svg'}
              sx={{ width: '12px', height: '20px', mr: '18px' }}
            />
            <TextField
              value={state.message}
              onChange={onChange}
              sx={{
                '& input::placeholder': { fontSize: '13px' },
                '& .MuiFormLabel-root': {
                  fontSize: '5opx'
                },
                '& .MuiInput-root': {
                  '&:before, :after, :hover:not(.mui-disabled):before': {
                    borderBottom: 0
                  }
                }
              }}
              fullWidth
              id="standard-textarea"
              placeholder={getWord('contractor_type')}
              multiline
              maxRows={2}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                style: {
                  minHeight: '26px',
                  padding: '12px 0 11px 0',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: COLOR_TEXT_MAIN
                }
              }}
            />
          </Box>
          <ButtonMain
            title={getWord('contractor_send')}
            onClick={onSend}
            fontSize={'14px'}
            fontWeight={500}
            lineHeight={'18px'}
            textBoxWidth={'fit-content'}
          />
        </Box>
      )}
    </LocalizationContext.Consumer>
  );
};
