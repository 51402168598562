import React, { FC, ReactElement, useState } from 'react';
import { Box } from '@mui/material';

import { OrderTabList } from '../OrderTabList';

import { COLOR_GREY_BACKGROUND, COLOR_GREY_TEXT } from '../../colors';

import { OrderTabType } from '../../types';

import { OrderList } from '../OrderList';

interface Props {
  onClick: (id: OrderTabType, index: number) => void;
}

interface State {
  activeId: OrderTabType;
  activeIndex: number;
}

export const OrderListManager: FC<Props> = (props): ReactElement => {
  const [state, setState] = useState<State>({ activeId: OrderTabType.inbox, activeIndex: -1 });

  const onChangeActiveIndex = (index: number) => {
    setState({ activeIndex: index, activeId: state.activeId });
    props.onClick(state.activeId, index);
  };
  const onChangeActiveId = (id: OrderTabType) => {
    setState({ activeIndex: -1, activeId: id });
    props.onClick(id, -1);
  };

  return (
    <Box
      sx={{
        backgroundColor: COLOR_GREY_BACKGROUND,
        minWidth: '448px',
        flexBasis: '448px',
        flexGrow: 1,
        flexShrink: 2,
        mt: '12px',
        borderRadius: '6px 6px 0 0 '
      }}>
      <OrderTabList onClick={onChangeActiveId} />
      <Box
        sx={{
          mx: '24px',
          mb: '25px',
          height: '1px',
          backgroundColor: COLOR_GREY_TEXT
        }}
      />
      <OrderList activeId={state.activeId} activeIndex={state.activeIndex} onClick={onChangeActiveIndex} />
    </Box>
  );
};
