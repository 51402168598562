import React, { FC, ReactElement } from 'react';
import { Box, Container } from '@mui/material';

import { UserInfo } from '../../features/Preview/UserInfo';
import { ServiceInfo } from '../../features/Preview/ServiceInfo';

interface Props {}

export const Preview: FC<Props> = (): ReactElement => (
  <Container
    maxWidth={false}
    style={{
      width: '100vw',
      height: '100vh',
      padding: 0,
      display: 'flex',
      flexDirection: 'row'
    }}>
    <Box
      sx={{
        width: '100%',
        height: '98%',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'flex-start'
      }}>
      <UserInfo />
      <ServiceInfo />
    </Box>
  </Container>
);
