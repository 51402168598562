import React, { FC, ReactElement } from 'react';
import { Box, Typography } from '@mui/material';

import { COLOR_TEXT_MAIN } from '../../../../colors';
import { LocalizationContext } from '../../../Localization';

interface Props {}

export const GoogleCalendarButton: FC<Props> = (): ReactElement => (
  <LocalizationContext.Consumer>
    {({ getWord }) => (
      <Box
        sx={{
          height: '12px',
          p: '7px 17px',
          border: '1px solid #143248',
          borderRadius: 70,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: '12px',
          '&:hover': {
            cursor: 'pointer'
          }
        }}>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 600,
            letterSpacing: 0.46,
            color: COLOR_TEXT_MAIN
          }}>
          {getWord('contrator_sync_with_google_calendar')}
        </Typography>
      </Box>
    )}
  </LocalizationContext.Consumer>
);
