import React, { FC, ReactElement, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { COLOR_ORANGE, COLOR_REVIEW_MESSAGE, COLOR_TEXT_MAIN } from '../../../colors';

interface Props {
  message: string;
  stars: number;
  photo?: string;
}

export const ReviewMessage: FC<Props> = ({ message, stars, photo }): ReactElement => {
  const [starsArr] = useState<number[]>(Array(stars).fill(0));
  return (
    <Box
      sx={{
        borderRadius: '8px',
        backgroundColor: COLOR_REVIEW_MESSAGE,
        p: '24px 12px',
        width: 'fit-content',
        maxWidth: '656px',
        my: '12px',
        border: `2px solid ${COLOR_ORANGE}`
      }}>
      {starsArr.length > 0 &&
        starsArr.map((star, index) => (
          <Box
            key={`star-${index}`}
            component={'img'}
            sx={{
              width: '14px',
              height: '12px',
              mr: '5px'
            }}
            src={'/images/svg/star.svg'}
          />
        ))}
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '23px',
          color: COLOR_TEXT_MAIN,
          my: '24px',
          fontStyle: 'italic'
        }}>
        {message}
      </Typography>
      {photo && (
        <Box
          component={'img'}
          sx={{
            width: '219px',
            height: '150px',
            objectFit: 'cover'
          }}
          src={photo}
        />
      )}
    </Box>
  );
};
