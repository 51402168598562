import React, { FC, ReactElement, useState } from 'react';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { selectProfile } from '../../../../app/redux/state';
import { updateProfile } from '../../../../app/redux/actions';
import { LocalizationContext } from '../../../Localization';

interface Props {
  needSaveProfile: boolean;
}

export const DescriptionInput: FC<Props> = ({ needSaveProfile }: Props): ReactElement => {
  const profile = useSelector(selectProfile);
  const dispatch = useDispatch();

  const [info, setInfo] = useState(profile.info);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setInfo(event.target.value);
  const onBlur = () => {
    if (info !== profile.info) {
      dispatch(updateProfile({ ...profile, info }, needSaveProfile));
    }
  };
  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <TextField
          onChange={onChange}
          onBlur={onBlur}
          value={info}
          sx={{
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: '#c4c4c4',
                borderWidth: '1px'
              }
            },
            mt: '16px',
            mb: '24px'
          }}
          inputProps={{
            style: {
              minHeight: '100px',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: 0.15
            }
          }}
          id="outlined-multiline-flexible"
          label={getWord('client_description')}
          multiline
          maxRows={3}
          fullWidth={true}
        />
      )}
    </LocalizationContext.Consumer>
  );
};
