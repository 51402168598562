import React, { FC, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ButtonMain } from '../../../features/ButtonMain';
import { ButtonDarkBlue } from '../../../features/ButtonDarkBlue';
import { FullNameInput } from '../../../features/ProfileInfo/components/FullNameInput';
import { CountryInput } from '../../../features/ProfileInfo/components/CountryInput';
import { CityInput } from '../../../features/ProfileInfo/components/CityInput';
import { CheckBox } from '../components/CheckBox';

import { selectProfile } from '../../../app/redux/state';
import { LogoMain } from '../../../features/LogoMain';
import { COLOR_TEXT_MAIN } from '../../../colors';
import { LocalizationContext } from '../../../features/Localization';
import { PhoneInput } from '../../../features/ProfileInfo/components/PhoneInput';

interface Props {}

interface State {
  isCheckedTerms: boolean;
}

export const SignUp0: FC<Props> = () => {
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);

  const [state, setState] = useState<State>({ isCheckedTerms: false });

  const onBack = () =>
    navigate('/login', {
      replace: true
    });

  const onNext = () =>
    navigate('/signUp/1', {
      replace: true
    });

  const isCanGoNext = () => profile?.fullName != null && profile.fullName.length > 0 && state.isCheckedTerms;

  const handleCheckedTerms = () => setState(prev => ({ ...prev, isCheckedTerms: !state.isCheckedTerms }));

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', ml: '35px', mt: '30px' }}>
            <LogoMain />
          </Box>
          <Box
            sx={{
              width: '566px'
            }}>
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '26px',
                color: COLOR_TEXT_MAIN,
                mb: '53px'
              }}>
              {getWord('contractor_signup')}
            </Typography>
            <FullNameInput needSaveProfile={false} />
            <PhoneInput needSaveProfile={false} />
            <CountryInput needSaveProfile={false} />
            <CityInput needSaveProfile={false} />
            <Box
              sx={{
                mt: '25px',
                mb: '45px'
              }}>
              <CheckBox isChecked={state.isCheckedTerms} handleChecked={handleCheckedTerms} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '56px'
              }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <ButtonMain
                  textBoxWidth="71px"
                  title="contractor_back"
                  onClick={onBack}
                  fontSize="16px"
                  fontWeight={500}
                  lineHeight="40px"
                />
              </Box>
              <Box
                sx={{
                  width: '342px'
                }}>
                <ButtonDarkBlue title="mobile_next" onClick={onNext} disabled={!isCanGoNext()} />
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </LocalizationContext.Consumer>
  );
};
