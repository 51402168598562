import React, { FC, ReactElement } from 'react';
import { Box } from '@mui/material';

import { TaskBoard } from './components/TaskBoard';
import { UserInfo } from './components/UserInfo';
import { ServiceButtons } from './components/ServiceButtons';
import { LogoMain } from '../LogoMain';

import { COLOR_GREY_BACKGROUND } from '../../colors';

interface Props {}

export const SideBar: FC<Props> = (): ReactElement => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minWidth: '142px',
      maxWidth: '142px'
    }}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <Box
        sx={{
          mt: '29px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <LogoMain />
        <Box
          sx={{
            height: '1px',
            width: '94px',
            backgroundColor: COLOR_GREY_BACKGROUND,
            mt: '20.23px'
          }}
        />
      </Box>
      <TaskBoard />
    </Box>
    <Box sx={{ mb: '76px' }}>
      <UserInfo />
      <ServiceButtons />
    </Box>
  </Box>
);
