import React, { FC, useState } from 'react';
import { Box, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';

import { ServiceCheckbox } from './components/ServiceCheckbox';

import { COLOR_TEXT_MAIN } from '../../colors';

import { selectServiceList } from '../../app/redux/state';

interface Props {
  id: number;
  name: string;
  checkedServiceId: Record<number, boolean>;
  onChecked: (value: number) => void;
}

interface State {
  isOpen: boolean;
}

export const CollapseCategory: FC<Props> = props => {
  const [state, setState] = useState<State>({ isOpen: false });

  const { id, name, checkedServiceId, onChecked } = props;
  const { isOpen } = state;
  const serviceList = useSelector(selectServiceList);
  const onClick = () =>
    setState({
      isOpen: !state.isOpen
    });

  return (
    <Box>
      <Box
        onClick={onClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '39px',
          px: '16px',
          mb: '12px',
          borderBottom: '1px solid #0000001F'
        }}>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 500,
            letterSpacing: 0.15,
            color: COLOR_TEXT_MAIN
          }}>
          {name}
        </Typography>
        {isOpen ? <ExpandMoreIcon sx={{ color: '#0000008F' }} /> : <ExpandLessIcon sx={{ color: '#0000008F' }} />}
      </Box>
      {isOpen &&
        serviceList
          .filter(s => s.categoryID === id)
          .map(s => (
            <ServiceCheckbox key={`service-${s.id}`} service={s} checkedId={checkedServiceId} onClick={onChecked} />
          ))}
    </Box>
  );
};
