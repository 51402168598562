import React, { PropsWithChildren } from 'react';
import { Container } from '@mui/material';

export const PageContainer = ({ children }: PropsWithChildren) => (
  <Container
    maxWidth={false}
    style={{
      width: '100vw',
      height: '100vh',
      padding: 0,
      display: 'flex',
      flexDirection: 'row'
    }}>
    {children}
  </Container>
);
