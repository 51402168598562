import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';

import { getStore } from '../redux/store';
import { Main } from '../../pages/main';
import { RequireAuth } from '../../features/RequireAuth';
import { initAnalytics } from '../../service/analytics';
import { LocalizationContextProvider } from '../../features/Localization';
import DetectChangeLocation from '../../features/DetectChangeLocation';
import { Login } from '../../pages/login';
import { SignUp0 } from '../../pages/signUp/0';
import { SignUp1 } from '../../pages/signUp/1';
import { SignUp2 } from '../../pages/signUp/2';
import { SignUp3 } from '../../pages/signUp/3';
import { Profile } from '../../pages/profile';
import { Preview } from '../../pages/preview';
import { EditService } from '../../pages/editService';
import { AddService0 } from '../../pages/addService/0';
import { AddService1 } from '../../pages/addService/1';
import { AddService2 } from '../../pages/addService/2';
import { Registration } from '../../pages/registration';

interface Props {}

interface State {
  store: unknown;
  loading: boolean;
}

export class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      store: getStore(),
      loading: false
    };

    initAnalytics();
  }

  render() {
    return (
      // @ts-ignore
      <Provider store={this.state.store}>
        <LocalizationContextProvider>
          <DetectChangeLocation />
          {!this.state.loading && (
            <Routes>
              <Route path="/login" Component={Login} />
              <Route path="/registration" Component={Registration} />
              <Route
                path="/signup/0"
                element={
                  <RequireAuth>
                    <SignUp0 />
                  </RequireAuth>
                }
              />
              <Route
                path="/signup/1"
                element={
                  <RequireAuth>
                    <SignUp1 />
                  </RequireAuth>
                }
              />
              <Route
                path="/signup/2"
                element={
                  <RequireAuth>
                    <SignUp2 />
                  </RequireAuth>
                }
              />
              <Route
                path="/signup/3"
                element={
                  <RequireAuth>
                    <SignUp3 />
                  </RequireAuth>
                }
              />
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Main />
                  </RequireAuth>
                }
              />
              <Route
                path="/profile"
                element={
                  <RequireAuth>
                    <Profile />
                  </RequireAuth>
                }
              />
              <Route
                path="/preview"
                element={
                  <RequireAuth>
                    <Preview />
                  </RequireAuth>
                }
              />
              <Route
                path="/edit/:index"
                element={
                  <RequireAuth>
                    <EditService />
                  </RequireAuth>
                }
              />
              <Route
                path="/addService/0"
                element={
                  <RequireAuth>
                    <AddService0 />
                  </RequireAuth>
                }
              />
              <Route
                path="/addService/1"
                element={
                  <RequireAuth>
                    <AddService1 />
                  </RequireAuth>
                }
              />
              <Route
                path="/addService/2"
                element={
                  <RequireAuth>
                    <AddService2 />
                  </RequireAuth>
                }
              />
            </Routes>
          )}
        </LocalizationContextProvider>
      </Provider>
    );
  }
}
