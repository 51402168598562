import React, { FC, ReactElement } from 'react';
import { Box, Typography } from '@mui/material';

import { COLOR_CHAT_MESSAGE, COLOR_WHITE } from '../../../colors';

interface Props {
  message: string;
}

export const OutgoingMessage: FC<Props> = ({ message }): ReactElement => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      py: '30px'
    }}>
    <Box
      sx={{
        borderRadius: '8px',
        backgroundColor: COLOR_CHAT_MESSAGE,
        p: '12px',
        maxWidth: '660px',
        width: 'fit-content',
        mb: '12px'
      }}>
      <Typography sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '23px', color: COLOR_WHITE }}>
        {' '}
        {message}
      </Typography>
    </Box>
  </Box>
);
