import { initialState } from './state';
import {
  APP_ACCEPT_ORDER,
  APP_COMPLETE_ORDER,
  APP_LOGOUT,
  APP_UPDATE_CATEGORY_LIST,
  APP_UPDATE_CHAT_MESSAGE_LIST,
  APP_UPDATE_COUNTRY_LIST,
  APP_UPDATE_LAST_CHAT_MESSAGE,
  APP_UPDATE_ORDERS,
  APP_UPDATE_PAYLOAD,
  APP_UPDATE_PHRASE,
  APP_UPDATE_PHRASE_SERVICE_LIST,
  APP_UPDATE_PROFILE,
  APP_UPDATE_SERVICE,
  APP_UPDATE_SERVICE_LIST,
  APP_UPDATE_TOKEN,
  APP_UPDATE_UNREAD_ARCHIVE_ORDER_MESSAGE_COUNT,
  APP_UPDATE_UNREAD_CHAT_MESSAGE_COUNT,
  APP_UPDATE_UNREAD_IN_PROGRESS_ORDER_MESSAGE_COUNT,
  APP_UPDATE_UNREAD_INBOX_ORDER_MESSAGE_COUNT,
  APP_UPDATE_USER_ID,
  IAppActionTypes
} from './actions';
import { OrderTabType } from '../../types';

export default function appReducer(state = initialState, action: IAppActionTypes) {
  let result = { ...state };
  switch (action.type) {
    case APP_UPDATE_USER_ID: {
      const { value } = action.payload;
      result = {
        ...state,
        userID: value
      };
      break;
    }
    case APP_UPDATE_CATEGORY_LIST: {
      const { data } = action.payload;
      result = {
        ...state,
        categoryList: [...data]
      };
      break;
    }
    case APP_UPDATE_SERVICE_LIST: {
      const { data } = action.payload;
      result = {
        ...state,
        serviceList: [...data]
      };
      break;
    }
    case APP_UPDATE_SERVICE: {
      const { value } = action.payload;
      result = {
        ...state,
        service: value
      };
      break;
    }
    case APP_UPDATE_TOKEN: {
      const { value } = action.payload;
      result = {
        ...state,
        token: value
      };
      break;
    }
    case APP_UPDATE_PAYLOAD: {
      const { value } = action.payload;
      result = {
        ...state,
        payload: { ...value }
      };
      break;
    }
    case APP_UPDATE_PROFILE: {
      result = {
        ...state,
        profile: action.payload.value
      };
      break;
    }
    case APP_UPDATE_UNREAD_CHAT_MESSAGE_COUNT: {
      result = {
        ...state,
        unreadChatMessageCount: action.payload.value
      };
      break;
    }
    case APP_UPDATE_UNREAD_INBOX_ORDER_MESSAGE_COUNT: {
      result = {
        ...state,
        unreadInboxOrderMessageCount: action.payload.value
      };
      break;
    }
    case APP_UPDATE_UNREAD_IN_PROGRESS_ORDER_MESSAGE_COUNT: {
      result = {
        ...state,
        unreadInProgressOrderMessageCount: action.payload.value
      };
      break;
    }
    case APP_UPDATE_UNREAD_ARCHIVE_ORDER_MESSAGE_COUNT: {
      result = {
        ...state,
        unreadArchiveOrderMessageCount: action.payload.value
      };
      break;
    }
    case APP_UPDATE_ORDERS: {
      result = {
        ...state,
        orderList: { ...action.payload.data }
      };
      break;
    }
    case APP_UPDATE_LAST_CHAT_MESSAGE: {
      result = {
        ...state,
        lastMessage: action.payload.value != null ? { ...action.payload.value } : null
      };
      break;
    }
    case APP_UPDATE_CHAT_MESSAGE_LIST: {
      result = {
        ...state,
        messageList: [...action.payload.data]
      };
      break;
    }
    case APP_LOGOUT: {
      result = {
        ...initialState
      };
      break;
    }
    case APP_UPDATE_COUNTRY_LIST: {
      result = {
        ...state,
        countryList: [...action.payload.data]
      };
      break;
    }
    case APP_UPDATE_PHRASE: {
      const { value } = action.payload;
      result = {
        ...state,
        phrase: value
      };
      break;
    }
    case APP_UPDATE_PHRASE_SERVICE_LIST: {
      const { data } = action.payload;
      result = {
        ...state,
        phraseServiceList: data
      };
      break;
    }
    case APP_ACCEPT_ORDER: {
      const index = state.orderList[OrderTabType.inbox].findIndex(order => order.id === action.payload.value);
      result =
        index > -1
          ? {
              ...state,
              orderList: {
                ...state.orderList,
                [OrderTabType.inProgress]: [
                  ...state.orderList[OrderTabType.inProgress],
                  { ...state.orderList[OrderTabType.inbox][index], state: 1 }
                ],
                [OrderTabType.inbox]: [
                  ...state.orderList[OrderTabType.inbox].slice(0, index),
                  ...state.orderList[OrderTabType.inbox].slice(index + 1)
                ]
              }
            }
          : state;
      break;
    }
    case APP_COMPLETE_ORDER: {
      const index = state.orderList[OrderTabType.inProgress].findIndex(order => order.id === action.payload.value);
      result =
        index > -1
          ? {
              ...state,
              orderList: {
                ...state.orderList,
                [OrderTabType.archive]: [
                  ...state.orderList[OrderTabType.archive],
                  { ...state.orderList[OrderTabType.inProgress][index], state: 1 }
                ],
                [OrderTabType.inProgress]: [
                  ...state.orderList[OrderTabType.inProgress].slice(0, index),
                  ...state.orderList[OrderTabType.inProgress].slice(index + 1)
                ]
              }
            }
          : state;
      break;
    }
    default: {
      result = { ...state };
    }
  }
  return result;
}
