import React, { FC, ReactElement, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { PhotoCardProfile } from '../../../../features/PhotoCardProfile';

import { COLOR_GREY_TEXT, COLOR_RED, COLOR_TEXT_MAIN } from '../../../../colors';

import { IExtendedService } from '../../../../types';

import { selectServiceList, selectToken } from '../../../../app/redux/state';

import { postImage } from '../../../../service/api';
import { LocalizationContext } from '../../../../features/Localization';

interface Props {
  index: number;
  service: IExtendedService;
  onUpdateDescription: (id: number, value: string) => void;
  onUpdatePhotoList: (id: number, data: string[]) => void;
  onRemove: (id: number) => void;
}
export const AddService: FC<Props> = (props): ReactElement => {
  const token = useSelector(selectToken);
  const serviceList = useSelector(selectServiceList);
  const [isPhotoOpen, setIsPhotoOpen] = useState(false);

  const onChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) =>
    props.onUpdateDescription(props.index, event.target.value);

  const onRemove = () => props.onRemove(props.index);

  const onImageChange = (event: any) => {
    if (event.target.files != null && event.target.files.length > 0) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        if (token != null) {
          postImage(token, [e.target.result])
            .then(data => {
              if (Array.isArray(data) && data.length > 0) {
                props.onUpdatePhotoList(props.index, [...service.imageUrlList, data[0]]);
              }
            })
            .catch(console.log);
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const { service } = props;
  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Box
          sx={{
            pb: '19px',
            borderBottom: `1px dashed ${COLOR_GREY_TEXT}`,
            mt: '21px'
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: '3px' }}>
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '18px',
                fontWeight: 700,
                lineHeight: '26px',
                letterSpacing: 0.46,
                color: COLOR_TEXT_MAIN
              }}>
              {/*@todo fix me*/}
              {service.serviceList.map(id => serviceList.find(obj => obj.id === id)?.name)}
            </Typography>
            <Box
              onClick={onRemove}
              sx={{
                display: 'flex',
                alignItems: 'center',
                ml: '8px',
                justifyContent: 'flex-start',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}>
              <Box
                component={'img'}
                sx={{ width: '14px', height: '24px', mr: '6px' }}
                src={'/images/svg/decline.svg'}
              />
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 600,
                  lineHeight: '26px',
                  letterSpacing: 0.46,
                  color: COLOR_RED
                }}>
                {getWord('contractor_remove')}
              </Typography>
            </Box>
          </Box>
          <TextField
            onChange={onChangeDescription}
            value={service.description}
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                  borderColor: '#c4c4c4',
                  borderWidth: '1px'
                }
              },
              mt: '16px',
              mb: '24px'
            }}
            inputProps={{
              style: {
                minHeight: '100px',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: 0.15
              }
            }}
            id="outlined-multiline-flexible"
            label={getWord('client_description')}
            multiline
            maxRows={3}
            fullWidth={true}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start'
            }}>
            <Box
              onClick={() => setIsPhotoOpen(!isPhotoOpen)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}>
              <Box sx={{ width: '15px', height: '16px', mr: '10px' }} component="img" src={'/images/svg/clip2.svg'} />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '26px',
                  color: COLOR_TEXT_MAIN
                }}>
                {getWord('contractor_add_photos')}
              </Typography>
            </Box>
          </Box>
          {isPhotoOpen && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                pt: '12px'
              }}>
              {service.imageUrlList.map((url, index) => (
                <PhotoCardProfile key={`addedPhoto-${index}`} src={url} />
              ))}
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                onChange={onImageChange}
                type="file"
                name="avatar"
              />
              <label htmlFor="raised-button-file">
                <Box sx={{ width: '96px', height: '84px' }} component="img" src={'/images/svg/addPhoto.svg'} />
              </label>
            </Box>
          )}
        </Box>
      )}
    </LocalizationContext.Consumer>
  );
};
