import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';

import { COLOR_WHITE, COLOR_ORANGE, COLOR_TEXT_MAIN } from '../../colors';
import { Avatar } from '../Avatar';

interface Props {
  onClick: () => void;
  unreadMessages: number;
  active: boolean;
  avatarUrl: string | null;
  name: string;
  time: string;
  lastMessage: string;
}

export class ChatPreview extends PureComponent<Props> {
  render() {
    const { onClick, unreadMessages, active, avatarUrl, name, time, lastMessage } = this.props;
    return (
      <Box
        onClick={onClick}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          m: '0 24px 12px 25px',
          p: unreadMessages ? '12px 12px 26px 12px' : '12px',
          borderRadius: '8px',
          backgroundColor: active ? COLOR_WHITE : 'transparent',
          '&:hover': {
            cursor: 'pointer'
          }
        }}>
        <Box
          sx={{
            position: 'relative',
            mr: '12px'
          }}>
          <Avatar avatarUrl={avatarUrl} fullName={name} />
          {unreadMessages > 0 && (
            <Box
              sx={{
                position: 'absolute',
                bottom: -15,
                left: 7,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: '1px solid white',
                backgroundColor: COLOR_ORANGE
              }}>
              <Typography sx={{ color: COLOR_WHITE, fontSize: '10px' }}>{unreadMessages}</Typography>
            </Box>
          )}
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              mb: '12px'
            }}>
            <Typography
              sx={{
                color: COLOR_TEXT_MAIN,
                fontSize: '12px',
                fontWeight: 700,
                mr: '7px',
                maxWidth: '270px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}>
              {name}
            </Typography>
            <Typography
              sx={{
                color: COLOR_TEXT_MAIN,
                fontSize: '8px',
                fontStyle: 'normal',
                fontWeight: 500,
                overflow: 'hidden',
                maxWidth: '150px',
                fontVariantNumeric: 'lining-nums'
              }}>
              {time}
            </Typography>
          </Box>
          <Typography
            sx={{
              color: COLOR_TEXT_MAIN,
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxHeight: '35px',
              lineHeight: '14px'
            }}>
            {lastMessage}
          </Typography>
        </Box>
      </Box>
    );
  }
}
