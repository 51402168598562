import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { selectCountryList, selectProfile } from '../../../../app/redux/state';
import { updateProfile } from '../../../../app/redux/actions';
import { LocalizationContext } from '../../../Localization';
import { ICity } from '../../../../types';
import { CheckBoxWholeCountry } from '../../../CheckBoxWholeCountry';

interface Props {
  needSaveProfile: boolean;
}

interface State {
  id: number;
  availableNationwide: boolean;
}

export const CityInput: FC<Props> = ({ needSaveProfile }: Props): ReactElement | null => {
  const dispatch = useDispatch();
  const countries = useSelector(selectCountryList);
  const profile = useSelector(selectProfile);

  const [cities, setCities] = useState<ICity[]>(countries.find(obj => obj.id === profile?.country?.id)?.cities ?? []);
  const [state, setState] = useState<State>({
    id: profile?.city?.id ?? 0,
    availableNationwide: profile?.city?.availableNationwide ?? false
  });

  //@todo null value
  useEffect(() => {
    const newCities = countries.find(obj => obj.id === profile?.country?.id)?.cities ?? [];
    setCities(newCities);
    setState({
      id: newCities.find(obj => obj.id === (profile.city?.id ?? 0))?.id ?? 0,
      availableNationwide: profile.city?.availableNationwide ?? false
    });
  }, [countries, profile.city?.id, profile.city?.availableNationwide, profile.country?.id]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.value as unknown as number;
    setState({ id, availableNationwide: state.availableNationwide });
    if (id !== profile.city?.id) {
      const city = cities.find(obj => obj.id === id);
      if (city != null) {
        dispatch(
          updateProfile(
            { ...profile, city: { id: city.id, code: city.code, availableNationwide: state.availableNationwide } },
            needSaveProfile
          )
        );
      }
    }
  };

  const handleAvailableNationwide = () => {
    setState({ id: state.id, availableNationwide: !state.availableNationwide });
    const city = cities.find(obj => obj.id === state.id);
    dispatch(
      updateProfile(
        {
          ...profile,
          city: {
            id: city != null ? city.id : null,
            code: city != null ? city.code : null,
            availableNationwide: !state.availableNationwide
          }
        },
        needSaveProfile
      )
    );
  };

  if (cities.length === 0 || state.id == null) {
    return null;
  }

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <>
          <Box
            sx={{
              mb: '21px'
            }}>
            <CheckBoxWholeCountry isChecked={state.availableNationwide} handleChecked={handleAvailableNationwide} />
          </Box>
          <TextField
            id="filled-select-countries"
            select
            disabled={state.availableNationwide}
            value={state.id}
            label={getWord('mobile_city')}
            fullWidth={true}
            onChange={onChange}
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                  borderColor: '#c4c4c4',
                  borderWidth: '1px'
                },
                height: '56px',
                mb: '12px'
              },
              height: '56px'
            }}>
            <MenuItem key={`city-0`} sx={{ display: 'flex', alignItems: 'center', p: '5px 10px' }} value={0}>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: 0.15
                }}>
                {getWord('contractor_select_city')}
              </Typography>
            </MenuItem>
            {cities.map(city => (
              <MenuItem key={city.id} sx={{ display: 'flex', alignItems: 'center', p: '5px 10px' }} value={city.id}>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: 0.15
                  }}>
                  {city.name}
                </Typography>
              </MenuItem>
            ))}
          </TextField>
        </>
      )}
    </LocalizationContext.Consumer>
  );
};
