import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { COLOR_GREY_TEXT, COLOR_TEXT_MAIN } from '../../colors';
import { LocalizationContext } from '../Localization';
import { CustomSelectLanguages } from '../CustomSelectLanguages';
import { Avatar } from './components/Avatar';
import { GoogleCalendarButton } from './components/GoogleCalendarButton';
import { ChangeAvatarButton } from './components/ChangeAvatarButton';
import { FullNameInput } from './components/FullNameInput';
import { CountryInput } from './components/CountryInput';
import { CityInput } from './components/CityInput';
import { DescriptionInput } from './components/DescriptionInput';
import { ProfilePreviewButton } from './components/ProfilePreviewButton';
import { ServiceButtons } from './components/ServiceButtons';
import { EmailInput } from './components/EmailInput';
import { ChangePasswordButton } from './components/ChangePasswordButton';
import { PhoneInput } from './components/PhoneInput';

interface Props {}

export const ProfileInfo: FC<Props> = () => (
  <Box
    sx={{
      mt: '12px',
      mx: '24px',
      flexBasis: '520px',
      flexGrow: 1,
      flexShrink: 2,
      minWidth: '520px'
    }}>
    <Box
      sx={{
        m: '24px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
      <LocalizationContext.Consumer>
        {({ getWord }) => (
          <Typography
            sx={{
              mr: '30px',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '26px',
              color: COLOR_TEXT_MAIN
            }}>
            {getWord('contractor_my_profile')}
          </Typography>
        )}
      </LocalizationContext.Consumer>
      <ProfilePreviewButton />
    </Box>
    <Box
      sx={{
        mb: '24px',
        height: '1px',
        backgroundColor: COLOR_GREY_TEXT
      }}
    />
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: '24px'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}>
        <GoogleCalendarButton />
        <ChangePasswordButton />
        <ChangeAvatarButton />
      </Box>
      <Avatar />
    </Box>
    <FullNameInput needSaveProfile={true} />
    <PhoneInput needSaveProfile={true} />
    <EmailInput />
    <CountryInput needSaveProfile={true} />
    <CityInput needSaveProfile={true} />
    <CustomSelectLanguages needSaveProfile={true} />
    <DescriptionInput needSaveProfile={true} />
    <ServiceButtons />
  </Box>
);
