import { channel } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { has } from 'lodash';
import { DateTime } from 'luxon';
import { io } from 'socket.io-client';
import { googleLogout } from '@react-oauth/google';

import {
  IChatMessage,
  ICountry,
  IInputChatMessage,
  IInputOrderMessage,
  IOrder,
  IOutputChatMessage,
  IOutputOrderMessage,
  IPage,
  IProfile,
  IReview,
  IService,
  ISuccessAuthorization,
  OrderTabType
} from '../../types';

import {
  APP_ACCEPT_ORDER,
  APP_AUTHORIZATION,
  APP_BASIC_LOGIN,
  APP_BASIC_REGISTRATION,
  APP_CHANGE_LANGUAGE,
  APP_COMPLETE_ORDER,
  APP_LOGOUT,
  APP_READ_CHAT_MESSAGE,
  APP_READ_ORDER_MESSAGE,
  APP_REFRESH_CATEGORY_LIST,
  APP_REFRESH_COUNTRY_LIST,
  APP_REFRESH_SERVICE,
  APP_SEND_CHAT_MESSAGE,
  APP_SEND_ORDER_MESSAGE,
  APP_UPDATE_PHRASE,
  APP_UPDATE_PROFILE,
  APP_UPLOAD_AVATAR,
  IAppAcceptOrderAction,
  IAppAuthorizationAction,
  IAppBasicRegistrationAction,
  IAppChangeLanguageAction,
  IAppCompleteOrderAction,
  IAppLogoutAction,
  IAppReadOrderMessageAction,
  IAppRefreshCategoryListAction,
  IAppRefreshCountryListAction,
  IAppRefreshServiceAction,
  IAppSendChatMessageAction,
  IAppSendOrderMessageAction,
  IAppUpdatePhraseAction,
  IAppUpdateProfileAction,
  IAppUploadAvatarAction,
  logout,
  refreshCategoryList,
  refreshCountryList,
  updateCategoryList,
  updateChatMessageList,
  updateCountryList,
  updateLastChatMessage,
  updateOrders,
  updatePhraseServiceList,
  updateProfile,
  updateService,
  updateServiceList,
  updateToken,
  updateUnreadArchiveOrderMessageCount,
  updateUnreadChatMessageCount,
  updateUnreadInboxOrderMessageCount,
  updateUnreadInProgressOrderMessageCount
} from './actions';

import env from '../../environment.json';
import { selectMessageList, selectProfile, selectToken } from './state';
import {
  deleteAccount,
  getBasicLoginToken,
  getBasicRegistrationToken,
  getCategoryList,
  getCountryList,
  getOauthToken,
  getPhraseServiceList,
  getProfile,
  getReviewList,
  getService,
  getServiceList,
  postImage,
  putProfile
} from '../../service/api';
import { AxiosError } from 'axios';
import { cDefaultLanguage } from '../../features/Localization';

const orderHistoryChannel = channel();
const chatHistoryChannel = channel();
const requestOrderHistory = channel();
const requestChatHistory = channel();

// @ts-ignore
const socket = io(env[env.environment].socket, {
  transports: ['websocket'],
  autoConnect: false,
  auth: {}
});

export const appSaga = [
  init(),
  takeEvery(requestOrderHistory, onRequestOrderHistory),
  takeEvery(orderHistoryChannel, onParseOrderHistory),
  takeEvery(requestChatHistory, onRequestChatHistory),
  takeEvery(chatHistoryChannel, onParseChatHistory),
  takeEvery(APP_SEND_CHAT_MESSAGE, onSendChatMessage),
  takeEvery(APP_SEND_ORDER_MESSAGE, onSendOrderMessage),
  takeEvery(APP_AUTHORIZATION, onAuthorization),
  takeEvery(APP_REFRESH_CATEGORY_LIST, onRefreshCategoryList),
  takeEvery(APP_REFRESH_SERVICE, onRefreshService),
  takeEvery(APP_REFRESH_SERVICE, onRefreshService),
  takeEvery(APP_READ_ORDER_MESSAGE, onReadOrderMessage),
  takeEvery(APP_READ_CHAT_MESSAGE, onReadChatMessage),
  takeEvery(APP_UPLOAD_AVATAR, onUploadAvatar),
  takeEvery(APP_UPDATE_PROFILE, onUpdateProfile),
  takeEvery(APP_LOGOUT, onLogout),
  takeEvery(APP_CHANGE_LANGUAGE, onChangeLanguage),
  takeEvery(APP_REFRESH_COUNTRY_LIST, onRefreshCountryList),
  takeEvery([APP_BASIC_REGISTRATION, APP_BASIC_LOGIN], onBasicAuthorization),
  takeLatest(APP_UPDATE_PHRASE, onUpdatePhraseServiceList),
  takeEvery(APP_ACCEPT_ORDER, onAcceptOrder),
  takeEvery(APP_COMPLETE_ORDER, onCompleteOrder)
];

function* init() {
  socket.on('connect', () => {
    console.log('connect:', socket.connected);
    requestOrderHistory.put(true);
    requestChatHistory.put(true);
  });

  socket.io.on('error', error => {
    console.log(error);
  });

  socket.on('receive_order_message', () => requestOrderHistory.put(true));
  socket.on('receive_chat_message', () => requestChatHistory.put(true));

  const token = localStorage.getItem('contractor.letsdoglobal.tech');
  if (token != null) {
    yield call(onLoadProfile, token);
    yield put(updateToken(token));

    socket.auth = { token };
    socket.connect();
  }

  // yield put(refreshCategoryList('en'));
  //
  // yield call(onRefreshCountryList);
}

function* onRequestOrderHistory() {
  socket.emit('contractor_order_history', (error: Error, data: IOutputOrderMessage[]) => {
    if (error != null) {
      console.log('contractor_order_history:', error);
    } else {
      orderHistoryChannel.put(data);
    }
  });
}

function* onParseOrderHistory(data: IOutputOrderMessage[]) {
  const reviewList: IReview[] = yield call(onLoadReviewList);
  const profile: IProfile = yield select(selectProfile);
  const orders: IOrder[] = [];
  let idToIndex: Record<number, number> = {};
  const unreadMessagesCount: Record<OrderTabType, number> = {
    [OrderTabType.inbox]: 0,
    [OrderTabType.inProgress]: 0,
    [OrderTabType.archive]: 0
  };

  data.forEach((obj: IOutputOrderMessage) => {
    unreadMessagesCount[obj.orderState < 3 ? (obj.orderState as OrderTabType) : OrderTabType.archive] +=
      obj.state === 0 && obj.senderId !== profile.id ? 1 : 0;
    const msg: IChatMessage = {
      id: obj.id,
      unread: obj.state === 0,
      value: obj.value,
      time:
        DateTime.fromISO(obj.createdAt).day === DateTime.now().day
          ? `${DateTime.fromISO(obj.createdAt).toFormat('HH:mm')}, today`
          : DateTime.fromISO(obj.createdAt).toFormat('HH:mm, MMM dd').toLowerCase(),
      root: obj.senderId !== profile.id,
      author: obj.sendFullName,
      topic: '',
      unixTime: DateTime.fromISO(obj.createdAt).toMillis(),
      avatarURL: obj.senderId !== profile.id ? obj.userAvatarURL : obj.contractorAvatarURL
    };

    let index = orders.length;
    if (has(idToIndex, obj.orderId)) {
      index = idToIndex[obj.orderId];
      orders[index].lastMessage = msg;
      orders[index].data.push(msg);
      if (obj.state === 0 && obj.senderId !== profile.id) {
        orders[index].unreadMessageIdList.push(obj.id);
      }
    } else {
      const review = reviewList.find(review => review.orderId === obj.orderId);
      orders.push({
        id: obj.orderId,
        state: obj.orderState,
        userID: obj.userId,
        userFullName: obj.userFullName,
        userAvatarURL: obj.userAvatarURL,
        serviceId: obj.serviceId,
        serviceFullName: obj.serviceFullName,
        lastMessage: msg,
        data: [msg],
        unreadMessageIdList: obj.state === 0 && obj.senderId !== profile.id ? [obj.id] : [],
        review: review ? review : null
      });
      idToIndex[obj.orderId] = orders.length - 1;
    }
  });
  orders.sort((a, b) => (a.lastMessage.unixTime > b.lastMessage.unixTime ? -1 : 1));
  const result: Record<OrderTabType, IOrder[]> = {
    [OrderTabType.inbox]: [],
    [OrderTabType.inProgress]: [],
    [OrderTabType.archive]: []
  };
  orders.forEach(order => {
    result[order.state < 3 ? (order.state as OrderTabType) : OrderTabType.archive].push(order);
  });
  yield put(updateOrders(result));
  yield put(updateUnreadInboxOrderMessageCount(unreadMessagesCount[OrderTabType.inbox]));
  yield put(updateUnreadInProgressOrderMessageCount(unreadMessagesCount[OrderTabType.inProgress]));
  yield put(updateUnreadArchiveOrderMessageCount(unreadMessagesCount[OrderTabType.archive]));
}

function* onRequestChatHistory() {
  socket.emit('chat_history', (error: Error, data: IOutputOrderMessage[]) => {
    if (error != null) {
      console.log('chat_history:', error);
    } else {
      chatHistoryChannel.put(data);
    }
  });
}

function* onParseChatHistory(data: IOutputChatMessage[]) {
  const profile: IProfile = yield select(selectProfile);
  const messages: IChatMessage[] = [];
  let unreadCount = 0;
  let lastMessage: IChatMessage | null = null;
  data.forEach((obj: IOutputChatMessage) => {
    messages.push({
      id: obj.id,
      unread: obj.state === 0,
      value: obj.value,
      time:
        DateTime.fromISO(obj.createdAt).day === DateTime.now().day
          ? `${DateTime.fromISO(obj.createdAt).toFormat('HH:mm')}, today`
          : DateTime.fromISO(obj.createdAt).toFormat('HH:mm, MMM dd').toLowerCase(),
      root: obj.userIdFrom !== profile.id,
      author: obj.userIdFrom === -1 ? 'Administrator' : 'You',
      topic: '',
      unixTime: DateTime.fromISO(obj.createdAt).toMillis(),
      avatarURL: 'images/avatar.png'
    });
    unreadCount += obj.userIdTo === profile.id && obj.state === 0 ? 1 : 0;
  });
  lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;

  yield put(updateChatMessageList(messages));
  yield put(updateLastChatMessage(lastMessage));
  yield put(updateUnreadChatMessageCount(unreadCount));
}

function* onAuthorization({ payload }: IAppAuthorizationAction) {
  const data: {
    token: string;
  } | null = yield call(getOauthToken, payload.idToken, payload.serverAuthCode, payload.language);
  if (data != null) {
    localStorage.setItem('contractor.letsdoglobal.tech', data.token);
    yield put(updateToken(data.token));
    yield call(onLoadProfile, data.token);

    // setUserID(data.userID);
    // yield put(updatePayload(data.payload));

    socket.disconnect();
    socket.auth = { token: data.token, username: 'desktop' };
    socket.connect();
  }
}

function* onBasicAuthorization(action: IAppBasicRegistrationAction) {
  const data: ISuccessAuthorization | AxiosError | null = yield call(
    action.type === APP_BASIC_REGISTRATION ? getBasicRegistrationToken : getBasicLoginToken,
    action.payload.login,
    action.payload.password,
    action.payload.language
  );
  if (data instanceof AxiosError) {
    alert(data.response?.data);
  } else {
    if (data != null) {
      localStorage.setItem('contractor.letsdoglobal.tech', data.token);
      yield put(updateToken(data.token));
      yield call(onLoadProfile, data.token);

      // setUserID(data.userID);
      // yield put(updatePayload(data.payload));

      socket.disconnect();
      socket.auth = { token: data.token, username: 'desktop' };
      socket.connect();
    }
  }
}

function* onRefreshCategoryList(action: IAppRefreshCategoryListAction) {
  const categoryList: { id: number; country: string; value: string }[] = yield call(
    getCategoryList,
    action.payload.language
  );

  yield put(updateCategoryList(categoryList));

  const serviceList: IService[] = yield call(getServiceList, action.payload.language);
  yield put(updateServiceList(serviceList));
}

function* onRefreshService(action: IAppRefreshServiceAction) {
  yield put(updateService(null));

  const service: IPage | null = yield call(getService, action.payload.value, action.payload.language);
  yield put(updateService(service));
}

function* onSendChatMessage({ payload }: IAppSendChatMessageAction) {
  const data: IInputChatMessage = {
    state: 0,
    userIdFrom: payload.userIdFrom,
    userIdTo: payload.userIdTo,
    value: payload.value
  };
  socket.emit('chat_message', data, (error: Error, _success: boolean) => {
    if (error != null) {
      console.log('onSendChatMessage:', error);
    } else {
      requestChatHistory.put(true);
    }
  });
}

function* onSendOrderMessage({ payload }: IAppSendOrderMessageAction) {
  const profile: IProfile = yield select(selectProfile);
  const data: IInputOrderMessage = {
    state: 0,
    orderId: payload.orderId,
    serviceId: payload.serviceId,
    userIdFrom: profile.id ?? -1,
    contractorId: profile.id,
    value: payload.value
  };
  socket.emit('order_message', data, (error: Error, _success: boolean) => {
    if (error != null) {
      console.log('onSendOrderMessage:', error);
    } else {
      requestOrderHistory.put(true);
    }
  });
}

function* onReadOrderMessage({ payload }: IAppReadOrderMessageAction) {
  const { idList } = payload;
  const profile: IProfile = yield select(selectProfile);
  if (profile != null && idList.length > 0) {
    socket.emit('read_order_message', profile.id, idList, (error: Error, _success: boolean) => {
      if (error != null) {
        console.log('onRateOrder:', error);
      } else {
        requestOrderHistory.put(true);
      }
    });
  }
}

function* onReadChatMessage() {
  const idList: number[] = [];
  const messages: IChatMessage[] = yield select(selectMessageList);
  messages.forEach(msg => msg.unread && msg.root && idList.push(msg.id));

  if (idList.length > 0) {
    socket.emit('read_chat_message', idList, (error: Error, _success: boolean) => {
      if (error != null) {
        console.log('onRateOrder:', error);
      } else {
        requestChatHistory.put(true);
      }
    });
  }
}

function* onUploadAvatar({ payload }: IAppUploadAvatarAction) {
  const token: string | null = yield select(selectToken);
  if (token != null) {
    const filenameList: string[] | AxiosError | Error = yield call(postImage, token, [payload.avatar]);
    if (Array.isArray(filenameList) && filenameList.length > 0) {
      const profile: IProfile = yield select(selectProfile);
      yield put(updateProfile({ ...profile, avatarURL: filenameList[0] }, true));
    }
  }
}

function* onUpdateProfile({ payload }: IAppUpdateProfileAction) {
  if (payload.needSave) {
    const token: string | null = yield select(selectToken);
    if (token != null) {
      const status: boolean | Error = yield call(putProfile, token, payload.value);
      yield call(onLoadProfile, token);
    }
  }
}

function* onRefreshCountryList({ payload }: IAppRefreshCountryListAction) {
  const data: ICountry[] = yield call(getCountryList, payload.language);

  yield put(updateCountryList(data));
}

function* onLoadProfile(token: string) {
  const data: IProfile = yield call(getProfile, token);
  if (data instanceof AxiosError && data?.response?.status === 401) {
    yield put(logout(false));
  } else {
    yield put(updateProfile(data, false));

    yield put(refreshCountryList(data.language ?? cDefaultLanguage.value));
    yield put(refreshCategoryList(data.language ?? cDefaultLanguage.value));
  }
}

function* onLogout({ payload }: IAppLogoutAction) {
  googleLogout();
  socket.disconnect();
  socket.auth = {};

  localStorage.removeItem('contractor.letsdoglobal.tech');

  if (payload.deleteAccount) {
    const status: boolean | Error = yield call(deleteAccount);
    console.log('deleteAccount:', status);
  }
}

function* onChangeLanguage({ payload }: IAppChangeLanguageAction) {
  //@todo check is auth
  const profile: IProfile = yield select(selectProfile);
  yield put(updateProfile({ ...profile, language: payload.value }, true));
}

function* onLoadReviewList() {
  let result: IReview[] = [];
  const token: string | null = yield select(selectToken);
  if (token != null) {
    result = yield call(getReviewList, token);
  }
  return result;
}

function* onUpdatePhraseServiceList(action: IAppUpdatePhraseAction) {
  if (action.payload.value != null && action.payload.value.trim().length > 0) {
    const data: IService[] = yield call(getPhraseServiceList, action.payload.value, action.payload.language);
    yield put(updatePhraseServiceList(data));
  } else {
    yield put(updatePhraseServiceList([]));
  }
}

function* onAcceptOrder({ payload }: IAppAcceptOrderAction) {
  socket.emit('order_accept', payload.value);
}

function* onCompleteOrder({ payload }: IAppCompleteOrderAction) {
  socket.emit('order_complete', payload.value);
}
