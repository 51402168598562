import React, { FC, ReactElement } from 'react';
import { Box, Link } from '@mui/material';

import { DeleteAccountButton } from '../../../DeleteAccountButton';

import { COLOR_GREY_TEXT, COLOR_TEXT_MAIN } from '../../../../colors';

import env from '../../../../environment.json';
import { LocalizationContext } from '../../../Localization';

interface Props {}

export const ServiceButtons: FC<Props> = (): ReactElement => {
  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Link
              sx={{
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '14px',
                color: COLOR_GREY_TEXT,
                textDecoration: 'none',
                '&:hover': {
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }
              }}
              href={env.policyURL}>
              {getWord('contractor_terms')}
            </Link>
            <DeleteAccountButton />
          </Box>
          <Box
            sx={{
              height: '12px',
              p: '7px 17px',
              border: '1px solid #143248',
              borderRadius: 70,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                cursor: 'pointer'
              }
            }}>
            <Link
              sx={{
                fontSize: '12px',
                fontWeight: 600,
                letterSpacing: 0.46,
                color: COLOR_TEXT_MAIN,
                textDecoration: 'none'
              }}
              href="mailto:info@letsgoglobal.app">
              {getWord('contractor_contact_us')}
            </Link>
          </Box>
        </Box>
      )}
    </LocalizationContext.Consumer>
  );
};
