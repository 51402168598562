import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { COLOR_BLUE, COLOR_GREY_TEXT, COLOR_TEXT_MAIN } from '../../colors';

import { PhotoCardProfile } from '../PhotoCardProfile';

import { IExtendedService } from '../../types';
import { useSelector } from 'react-redux';
import { selectServiceList } from '../../app/redux/state';
import { LocalizationContext } from '../Localization';

interface Props {
  service: IExtendedService;
  isMorePhoto: boolean;
  canEdit: boolean;
  onEditService: () => void;
}

export const ServiceViewProfile: FC<Props> = ({ service, isMorePhoto, canEdit, onEditService }) => {
  const serviceList = useSelector(selectServiceList);
  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <Box
          sx={{
            m: ' 0 30px 0 23px'
          }}>
          <Box
            sx={{
              height: '26px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '18px',
                fontWeight: 700,
                lineHeight: '26px',
                letterSpacing: 0.46,
                color: COLOR_TEXT_MAIN
              }}>
              {/*@todo fix me*/}
              {service.serviceList.map(id => serviceList.find(obj => obj.id == id)?.name)}
            </Typography>
            {canEdit && (
              <Box
                onClick={onEditService}
                sx={{
                  ml: '17px',
                  height: '26px',
                  p: '0 17px',
                  border: '1px solid #143248',
                  borderRadius: 70,
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 600,
                    letterSpacing: 0.46,
                    color: COLOR_TEXT_MAIN
                  }}>
                  {getWord('contractor_edit')}
                </Typography>
              </Box>
            )}
          </Box>
          <Typography
            sx={{
              my: '17px',
              width: '634px',
              fontSize: '12px',
              fontWeight: 400,
              letterSpacing: 0.15,
              color: COLOR_TEXT_MAIN
            }}>
            {service.description}
          </Typography>
          <Box
            sx={{
              width: '642px',
              display: 'flex',
              flexWrap: 'wrap'
            }}>
            {service.imageUrlList.map((url, index) => (
              <PhotoCardProfile key={`PhotoCardProfile-${index}`} src={url} />
            ))}
          </Box>
          {isMorePhoto && (
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                letterSpacing: 0.46,
                color: COLOR_BLUE,
                lineHeight: '26px',
                fontVariantNumeric: 'lining-nums',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}>
              {getWord('contractor_show_all')} 35 {getWord('contractor_photos')}
            </Typography>
          )}
          <Box
            sx={{
              my: '25px',
              height: '1px',
              backgroundColor: COLOR_GREY_TEXT
            }}
          />
        </Box>
      )}
    </LocalizationContext.Consumer>
  );
};
